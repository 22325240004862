import React, { useEffect, useState } from "react";
import StrategyService from "../services/StrategyService";
import "./OrderFragment.css";
import OrderItem from "./OrderItem";

function OrderFragment() {
  // Function to set time to midnight (00:00:00)
  const setToMidnight = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };
  // State variables for today and tomorrow
  const [today, setToday] = useState(setToMidnight(new Date()));
  const [tomorrow, setTomorrow] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return setToMidnight(date);
  });

  const [excutedOrders, setExcutedOrders] = useState(null);

  // Function to move dates one day back
  const moveLeft = () => {
    const newToday = new Date(today);
    newToday.setDate(newToday.getDate() - 1);
    setToday(setToMidnight(newToday));
    setTomorrow(setToMidnight(today));
    fetchOrders();
  };

  // Function to move dates one day forward
  const moveRight = () => {
    const newToday = new Date(today);
    newToday.setDate(newToday.getDate() + 1);
    setToday(setToMidnight(newToday));
    const newTomorrow = new Date(newToday);
    newTomorrow.setDate(newTomorrow.getDate() + 1);
    setTomorrow(setToMidnight(newTomorrow));
    fetchOrders();
  };

  // Effect to initialize today and tomorrow on component mount
  useEffect(() => {
    // const initialTomorrow = new Date(today);
    // initialTomorrow.setDate(initialTomorrow.getDate() + 1);
    // setToday(setToMidnight(today));
    // setTomorrow(setToMidnight(initialTomorrow));
    fetchOrders();
  }, []); // Effect runs whenever `today` changes

  // Function to simulate fetching orders
  const fetchOrders = () => {
    console.log("starting fetching");
    StrategyService.fetchExecutedOrders(
      tomorrow,
      today,
      (orders) => {
        console.log("fetched order");
        setExcutedOrders(orders);
      },
      (error) => {
        // Handle error here if needed
      }
    );
    // Replace this with your actual fetch logic
    console.log("Fetching orders for:", today, tomorrow);
  };

  return (
    <div className="order-fragment">
      <div className="order-fragment-inner">
        <p className="left">{today.toDateString()}</p>
        <p className="right">{tomorrow.toDateString()}</p>
      </div>

      <div className="date-info grid-container">
        {excutedOrders &&
          excutedOrders.map((order, index) => <OrderItem order={order} />)}
      </div>
      <div className="move-container">
        <button className="left-button" onClick={moveLeft}>
          Move One day back
        </button>
        <button className="right-button" onClick={moveRight}>
          Move one day forward
        </button>
      </div>
    </div>
  );
}

export default OrderFragment;
