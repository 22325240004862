import React, { useEffect, useState } from "react";
import ChartsService from "./ChartsService";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController,
} from "chart.js";
import "./ChartsService.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  LineController
);

function ChartsFragment() {
  const [combinedData, setCombinedData] = useState([]);
  const [oiDataFiveMin, setOIFiveMin] = useState([]);
  const [lineData, setLineData] = useState([]);

  useEffect(() => {
    fetchOIData();
    fetchFiveMinData();
  }, []);

  const fetchFiveMinData = () => {
    ChartsService.getOIFiveMinData(
      (response) => {
        setOIFiveMin(response[0]);
        setLineData(response[1]);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const fetchOIData = () => {
    ChartsService.getOpenInterest(
      (response) => {
        setCombinedData(response);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getForInterevalLineChart = (interval) => {
    ChartsService.getOIFiveMinDataForIntervalLineChart(
      interval,
      (response) => {
        setLineData(response);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const getForInterevalTableChart = (interval) => {
    ChartsService.getOIFiveMinDataForIntervalTableChart(
      interval,
      (response) => {
        setOIFiveMin(response);
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getLineChartData = () => {
    const labels = lineData.map((item) =>
      new Date(Number(item.time)).toLocaleTimeString()
    );

    return {
      labels: labels,
      datasets: [
        {
          data: lineData.map((item) => item.value),
          borderColor: "#2d3191",
        },
      ],
    };
  };

  // Define the chart options separately
  const lineChartOptions = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          // Customize the Y-axis values here
          callback: function (value) {
            return `${value / 100000}L`; // Divide the value by 1 lac and add 'L'
          },
        },
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const value = context.raw;
          return `Value: ${value / 100000}L`; // Format tooltip value
        },
      },
      backgroundColor: "green", // Set the tooltip background color
      bodyColor: "black", // Set the tooltip text color
      titleColor: "black", // Set the tooltip title text color
      borderColor: "black", // Set the tooltip border color
      borderWidth: 1, // Set the tooltip border width
    },
  };

  return (
    <div className="chart-container">
      <div className="bars-container">
        <div className="bars-content">
          <h1 className="title-chart">Open Interest - in Lacs </h1>
          <ResponsiveContainer>
            <BarChart data={combinedData}>
              <XAxis
                dataKey="key"
                angle={-60}
                textAnchor="end"
                interval={0}
                tick={{ fontSize: 10 }}
              />
              <YAxis tickFormatter={(value) => `${value} L`} />
              <Tooltip />
              <Bar
                dataKey="open_interest_ce"
                fill="#8884d8"
                stackId="a"
                barSize={40}
              />
              <Bar
                dataKey="open_interest_pe"
                fill="#82ca9d"
                stackId="a"
                barSize={40}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="bars-content">
          <h1 className="title-chart">Open Interest Change - in Lacs</h1>
          <ResponsiveContainer>
            <BarChart data={combinedData}>
              <XAxis
                dataKey="key"
                angle={-60}
                textAnchor="end"
                interval={0}
                tick={{ fontSize: 10 }}
              />
              <YAxis tickFormatter={(value) => `${value} L`} />
              <Tooltip />
              <Bar
                dataKey="open_interest_change_ce"
                fill="#8884d8"
                stackId="a"
                barSize={40}
              />
              <Bar
                dataKey="open_interest_change_pe"
                fill="#82ca9d"
                stackId="a"
                barSize={40}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bars-container">
        <div className="bars-content">
          <h1 className="title-chart">Time Vs Diff</h1>

          {/* <Line data={getLineChartData()} options={{ responsive: true }} /> */}
          <Line data={getLineChartData()} options={lineChartOptions} />

          <div className="table-button-container">
            <button
              className="time-button"
              onClick={() => getForInterevalLineChart(5)}
            >
              5 M
            </button>
            <button
              className="time-button"
              onClick={() => getForInterevalLineChart(15)}
            >
              15 M
            </button>
            <button
              className="time-button"
              onClick={() => getForInterevalLineChart(30)}
            >
              30 M
            </button>
            <button
              className="time-button"
              onClick={() => getForInterevalLineChart(60)}
            >
              1 H
            </button>
            <button
              className="time-button"
              onClick={() => getForInterevalLineChart(120)}
            >
              2 H
            </button>
          </div>
        </div>

        <div className="bars-content" style={{ marginBottom: "250px" }}>
          <h1 className="title-chart">Signals</h1>
          <div className="table-chart-container">
            <div className="table-row">
              <div className="table-headerCell">Time</div>
              <div className="table-headerCell">Call</div>
              <div className="table-headerCell">Put</div>
              <div className="table-headerCell">Diff</div>
              <div className="table-headerCell">PCR</div>
              <div className="table-headerCell">Optional Signal</div>
              <div className="table-headerCell">VWAP</div>
              <div className="table-headerCell">Price</div>
              <div className="table-headerCell">VWAP Signal</div>
            </div>

            <div>
              {oiDataFiveMin &&
                oiDataFiveMin.map((item, index) => (
                  <div className="table-row" key={index}>
                    <div className="table-cell">{item.time}</div>
                    <div className="table-cell">{item.call}</div>
                    <div className="table-cell">{item.put}</div>
                    <div className="table-cell">{item.diff}</div>
                    <div
                      className="table-cell"
                      style={{
                        backgroundColor:
                          parseFloat(item.pcr) >= 1.0 ? "green" : "red",
                      }}
                    >
                      {item.pcr}
                    </div>
                    <div
                      className="table-cell"
                      style={{
                        backgroundColor:
                          item.option_signal === "SELL" ? "red" : "green",
                      }}
                    >
                      {item.option_signal}
                    </div>
                    <div className="table-cell">{item.vwap}</div>
                    <div className="table-cell">{item.nifty_price}</div>
                    <div
                      className="table-cell"
                      style={{
                        backgroundColor:
                          item.vwap_signal === "SELL" ? "red" : "green",
                      }}
                    >
                      {item.vwap_signal}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="table-button-container">
            <button
              className="time-button"
              onClick={() => getForInterevalTableChart(5)}
            >
              5 M
            </button>
            <button
              className="time-button"
              onClick={() => getForInterevalTableChart(15)}
            >
              15 M
            </button>
            <button
              className="time-button"
              onClick={() => getForInterevalTableChart(30)}
            >
              30 M
            </button>
            <button
              className="time-button"
              onClick={() => getForInterevalTableChart(60)}
            >
              1 H
            </button>
            <button
              className="time-button"
              onClick={() => getForInterevalTableChart(120)}
            >
              2 H
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartsFragment;
