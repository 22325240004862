import React, { useState, useEffect } from "react";
import StrategyService from "../services/StrategyService";
import "./BrokersFragment.css";
import MovableDialog from "./MovableDialog";
import KotakNeoForm from "./KotakNeoForm";

function BrokersFragment() {
  const [brokers, setBrokers] = useState([]);
  const [selectedBroker, setSelectedBroker] = useState(null);

  const fetchBrokers = () => {
    StrategyService.fetchBrokers(
      (brokers) => {
        setBrokers(brokers);
      },
      (error) => {
        alert("Error: " + error);
      }
    );
  };

  useEffect(() => {
    fetchBrokers();
    loadDetails();
  }, []);

  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [mpin, setMpin] = useState("");
  const [consumerKey, setConsumerKey] = useState("");
  const [consumerSecret, setConsumerSecret] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [brokerDetails, setBrokerDetails] = useState(null); // Initialize with null or appropriate initial value

  const loadDetails = () => {
    StrategyService.fetchBrokerDetails(
      "NEO",
      (mbrokerDetails) => {
        setBrokerDetails(mbrokerDetails);
        const metadata = mbrokerDetails.broker_metadata;
        if (metadata != null) {
          setMobile(metadata.mobile_number.substring(3) || "");
          setPassword(metadata.password || "");
          setMpin(metadata.mpin || "");
          setConsumerKey(metadata.consumer_key || "");
          setSessionToken(metadata.session_token || null);
          setConsumerSecret(metadata.consumer_secret || "");
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const confirmationBroker = (broker) => {
    setSelectedBroker(broker);
  };

  return (
    <div>
      {brokers.map((broker) => (
        <div
          key={broker.id}
          className="broker-container"
          onClick={() => confirmationBroker(broker)}
        >
          <p className="broker-title">{broker.id}</p>
          {broker.id.toLowerCase() === "neo" && sessionToken && (
            <p
              className="save"
              style={{ backgroundColor: "green", color: "white" }}
            >
              Activate
            </p>
          )}
          {broker.id.toLowerCase() === "neo" && !sessionToken && (
            <p
              className="save"
              style={{ backgroundColor: "red", color: "black" }}
            >
              Save
            </p>
          )}
        </div>
      ))}

      {selectedBroker && selectedBroker.id.toLowerCase() === "mock_broker" && (
        <MovableDialog onClose={() => setSelectedBroker(null)}>
          <p className="broker-title-dailog"> {selectedBroker.id} </p>
          <p className="broker-description-dailog">
            No need to save Mock Broker
          </p>
        </MovableDialog>
      )}
      {selectedBroker && selectedBroker.id.toLowerCase() === "neo" && (
        <MovableDialog onClose={() => setSelectedBroker(null)}>
          <KotakNeoForm broker={selectedBroker} />
        </MovableDialog>
      )}
    </div>
  );
}

export default BrokersFragment;
