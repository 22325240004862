import React, { useState } from "react";
import "./OrderDetails.css";
import BarChart from "./OrderChart";
import nifttyUpIcon from "../images/nifty_up.png";
import nifttydownIcon from "../images/nifty_down.png";
import strategyIcon from "../images/charts.png";
import UnknownStatusIcon from "../images/question_mark.png";
import brokerIcon from "../images/broker.png";
import playIcon from "../images/play.png";
import timeIcon from "../images/time.png";
import rupee from "../images/rupee.png";
import candleIcon from "../images/candle.png";
import idIcon from "../images/id.png";
import maxProfitIcon from "../images/max_profit.png";
import minProfitIcon from "../images/min_profit.png";
import maxLossIcon from "../images/max_loss.png";
import lots from "../images/lots.png";
import OrderChart from "./OrderChart";
import StrategyService from "../services/StrategyService";
import { auth } from "./register/firebase";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";

function OrderDetails({ order, onClose }) {
  // Destructure the relevant data from the order object with safe checks
  const {
    stock = "N/A",
    strategy_metadata: {
      initial_capital = "N/A",
      strategy_name = "N/A",
      start_time,
      additional_metadata: {
        profit_range_max = { value: "N/A" },
        max_lots = { value: "N/A" },
        profit_range_min = { value: "N/A" },
        max_loss = { value: "N/A" },
      } = {},
    } = {},
    strategy_status = "N/A",
    user_id = "N/A",
    broker_type = "N/A",
    current_strategy_status = "N/A",
  } = order || {};

  const formatTime = (timestamp) => {
    if (!timestamp || !timestamp.seconds || !timestamp.nanoseconds)
      return "N/A";
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6
    );
    return (
      date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) +
      ", " +
      date.toLocaleDateString()
    );
  };

  const [showDialog, setShowDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleStopClick = () => {
    setShowDialog(true);
  };

  const handleConfirm = () => {
    setLoading(true);
    setShowDialog(false);
    setOpenDialog(true);
    // Construct the request body
    const strategy = {
      user_id: auth.currentUser?.uid,
      strategy_id: order?.id,
    };

    StrategyService.stopStrategy(
      strategy,
      (data) => {
        // Success callback
        setDialogMessage("Success: " + JSON.stringify(data));
        alert("Success: " + JSON.stringify(data));
        setOpenDialog(false);
        setLoading(false);
      },
      (error) => {
        // Failure callback
        setDialogMessage("Error: " + error.message);
        alert("Error: " + error.message);
        setOpenDialog(false);
        setLoading(false);
      }
    );
  };

  return (
    <div>
      <div className="order-details-main-container">
        <div className="order-details-container">
          <div className="strategy-section">
            <img src={strategyIcon} alt="Chart" className="icon" />
            <div className="strategy-name">{strategy_name}</div>
          </div>
          <div className="ltp-section">
            <div className="ltp-item">
              <img src={nifttyUpIcon} alt="LTP Status" className="icon" />
              <div className="ltp-text">ltp</div>
            </div>
            <div className="ltp-item">
              <img src={nifttyUpIcon} alt="Daily LTP Status" className="icon" />
              <div className="ltp-text">ltp</div>
            </div>
          </div>
          <div className="status-section">
            <img
              src={UnknownStatusIcon}
              alt="Unknown Status"
              className="icon"
            />
            <div className="status-text">{current_strategy_status}</div>
          </div>
          <div className="separator" />
          <div className="status-running-section">
            <img src={playIcon} alt="Running" className="icon" />
            <div className="status-text">{strategy_status}</div>
          </div>
          <div className="separator" />
          <div className="capital-section">
            <img src={rupee} alt="Capital" className="icon" />
            <div className="capital-text">Rs. {initial_capital} /-</div>
          </div>
          <div className="separator" />
          <div className="broker-section">
            <img src={brokerIcon} alt="Broker" className="icon" />
            <div className="broker-text">{broker_type}</div>
          </div>
          <div className="separator" />
          <div className="time-section">
            <img src={timeIcon} alt="Time" className="icon" />
            <div className="time-text">{formatTime(start_time)}</div>
          </div>
          <div className="separator" />
          <div className="time-section">
            <img src={candleIcon} alt="Stock" className="icon" />
            <div className="stock-text">{stock}</div>
          </div>
          <div className="separator" />
          <div className="strategy-id-section">
            <img
              src={idIcon}
              alt="Strategy ID"
              className="icon"
              style={{ transform: "scaleX(-1)" }}
            />
            <div className="strategy-id-text">{order?.id}</div>
          </div>
          <div className="separator" />
          <div className="profit-loss-section">
            <div className="profit-item">
              <div className="time-section">
                <img src={maxProfitIcon} alt="Max Profit" className="icon" />
                <div className="profit-text">Max profit</div>
              </div>
              <div className="profit-value">{profit_range_max.value}</div>
            </div>
            <div className="profit-item">
              <div className="time-section">
                <img src={minProfitIcon} alt="Min Profit" className="icon" />
                <div className="profit-text">Min profit</div>
              </div>
              <div className="profit-value">{profit_range_min.value}</div>
            </div>
          </div>
          <div className="profit-loss-section">
            <div className="profit-item">
              <div className="time-section">
                <img src={lots} alt="Max Lots" className="icon" />
                <div className="profit-text">Max lots</div>
              </div>
              <div className="profit-value">{max_lots.value}</div>
            </div>
            <div className="profit-item">
              <div className="time-section">
                <img
                  src={maxLossIcon}
                  alt="Max Loss"
                  className="icon"
                  style={{ transform: "rotateX(180deg)" }}
                />
                <div className="profit-text">Max loss</div>
              </div>
              <div className="profit-value">{max_loss.value}</div>
            </div>
          </div>
          <div
            className={
              strategy_status === "RUNNING"
                ? "stop-strategy-button"
                : "non-stop-strategy-button"
            }
            onClick={handleStopClick}
          >
            {strategy_status === "RUNNING" ? "STOP" : "STOPPED"}
          </div>
        </div>
        <div className="graph-details-container">
          <OrderChart order={order} />
        </div>
      </div>
      {showDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <p className="dialog-message">
              Are you sure you want to stop this strategy?
            </p>
            <div className="dialog-buttons">
              <button className="dialog-button" onClick={handleClose}>
                No
              </button>
              <button
                className="dialog-button dialog-button-confirm"
                onClick={handleConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <Dialog open={openDialog}>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <p>{"Stopping strategy..."}</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OrderDetails;
