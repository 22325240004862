import React from "react";
import "./PrivacyPolicy.css"; // Import your CSS file

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy for AlgoTurtle</h1>
      <p>
        <strong>Last updated:</strong> [Insert Date]
      </p>

      <h2>1. Introduction:</h2>
      <p>
        Welcome to AlgoTurtle! This Privacy Policy outlines how we collect, use,
        disclose, and safeguard your personal information when you use our
        website and services.
      </p>

      <h2>2. Information We Collect:</h2>
      <p>
        a. <strong>Personal Information:</strong>
        <br />
        Name, email address, contact details.
        <br />
        Financial information (e.g., bank account details) for payment
        processing.
        <br />
        Identification information (e.g., government-issued ID) for account
        verification.
      </p>

      <p>
        b. <strong>Technical Information:</strong>
        <br />
        IP address, browser type, device type, operating system.
        <br />
        Cookies and similar tracking technologies.
      </p>

      <p>
        c. <strong>Usage Data:</strong>
        <br />
        Pages visited, time spent on each page, interactions with our website.
      </p>

      <h2>3. How We Use Your Information:</h2>
      <p>
        a. Provide and maintain our services.
        <br />
        b. Personalize user experience and improve our website.
        <br />
        c. Communicate with you regarding updates, promotions, and important
        announcements.
        <br />
        d. Detect and prevent fraudulent activities.
        <br />
        e. Comply with legal obligations.
      </p>

      <h2>4. Sharing Your Information:</h2>
      <p>
        a. We may share your information with trusted third parties such as:
        <br />
        - Payment processors for transaction processing.
        <br />
        - Service providers for website hosting, maintenance, and analytics.
        <br />- Legal authorities for compliance with legal requirements.
      </p>

      <p>
        b. We do not sell or rent your personal information to third parties for
        marketing purposes.
      </p>

      <h2>5. Security Measures:</h2>
      <p>
        a. We implement industry-standard security measures to protect your
        personal information from unauthorized access, disclosure, alteration,
        and destruction.
        <br />
        b. Encryption, firewalls, access controls, and regular security audits
        are employed to safeguard your data.
      </p>

      <h2>6. Your Rights and Choices:</h2>
      <p>
        a. You have the right to access, correct, or delete your personal
        information. You can manage your account settings to update your
        information.
        <br />
        b. You can choose to opt-out of receiving promotional communications
        from us.
        <br />
        c. You may disable cookies and tracking technologies, but this may
        affect the functionality of our website.
      </p>

      <h2>7. Data Retention:</h2>
      <p>
        We retain your personal information only for as long as necessary to
        fulfill the purposes outlined in this Privacy Policy and as required by
        law.
      </p>

      <h2>8. International Transfers:</h2>
      <p>
        Your personal information may be transferred to and processed in
        countries outside of your jurisdiction where data protection laws may
        differ. By using our services, you consent to such transfers.
      </p>

      <h2>9. Children's Privacy:</h2>
      <p>
        Our services are not directed to individuals under the age of 18. We do
        not knowingly collect personal information from children. If you are a
        parent or guardian and believe that your child has provided us with
        personal information, please contact us to request deletion.
      </p>

      <h2>10. Changes to This Privacy Policy:</h2>
      <p>
        We reserve the right to update this Privacy Policy at any time. We will
        notify you of any changes by posting the new Privacy Policy on this
        page.
      </p>

      <h2>11. Contact Us:</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at [insert contact information].
      </p>
    </div>
  );
}

export default PrivacyPolicy;
