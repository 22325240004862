import React from "react";
import { FaTimesCircle } from "react-icons/fa"; // Importing failure icon
import "./FailurePage.css";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const FailurePage = () => {
  return (
    <div>
      <Navbar />
      <div className="failure-container">
        <FaTimesCircle className="failure-icon" />
        <h1 className="failure-heading">Payment Failed</h1>
        <p className="failure-message">
          Unfortunately, your payment could not be processed.
        </p>
        <Link to="/">Retry</Link>
      </div>
      <Footer />
    </div>
  );
};

export default FailurePage;
