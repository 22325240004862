import React, { useEffect, useState } from "react";
import { db, auth } from "./register/firebase";

import { signOut } from "firebase/auth";
import "./StrategyCard.css";
import icon from "./stop_circle.png";
import userIcon from "../images/user_pic.png";
import brokerIcon from "../images/broker.png";
import planB from "../images/crown_gray.png";
import planP from "../images/crown.png";
import phoneIcon from "../images/phone.png";
import logoutIcon from "../images/logout.png";
import infoIcon from "../images/info.png";
import csIcon from "../images/customer_support.png";

const UserDetails = ({ userDetails, onIconClick }) => {
  const signout = async () => {
    try {
      await signOut(auth);
      alert("You have successfully signed out.");
    } catch (error) {
      console.error("Error signing out: ", error);
      alert("Error signing out. Please try again.");
    }
  };

  return (
    <div className="profile-container">
      <div className="linear-layout">
        <div className="horizontal-layout">
          <div className="profile-image-container">
            <img
              src={
                userDetails && userDetails.imageUri
                  ? userDetails.imageUri
                  : userIcon
              }
              alt="user"
              className="profile-image"
            />
          </div>
          <div className="profile-details">
            <h3 id="name_text" className="profile-name">
              {userDetails && userDetails.name
                ? userDetails.name
                : "Name not available"}
            </h3>
            <p id="email_text" className="profile-email">
              {userDetails && userDetails.email
                ? userDetails.email
                : "Email not available"}
            </p>
          </div>
        </div>
        <hr className="divider" />
        <div className="horizontal-layout-pointer" onClick={onIconClick}>
          <img className="icon-filtered" src={brokerIcon} alt="Brokers" />
          <h3 id="brokers_text" className="  user-title">
            Brokers
          </h3>
        </div>
        <hr className="divider" />
        <div className="horizontal-layout">
          <img
            className="icon"
            src={
              userDetails &&
              (userDetails.plan_type === "basic" ||
                userDetails.plan_type === "")
                ? planB
                : planP
            }
            alt="Plan Type"
          />
          <h3 id="text_plan_type" className="  user-title">
            {userDetails && userDetails.plan_type
              ? userDetails.plan_type === ""
                ? "Payment pending"
                : userDetails.plan_type
              : "Payment pending"}
          </h3>
        </div>
        <hr className="divider" />
        <div className="horizontal-layout">
          <img className="icon-filtered" src={phoneIcon} alt="Phone Number" />
          <h3 id="phone_number" className="  user-title">
            {userDetails && userDetails.phoneNumber
              ? userDetails.phoneNumber
              : "Phone number not available"}
          </h3>
        </div>
        <hr className="divider" />
        <div className="horizontal-layout">
          <img className="icon-filtered" src={csIcon} alt="Customer Support" />
          <h3 id="customer_support" className="  user-title">
            Customer support
          </h3>
        </div>
        <hr className="divider" />
        <div className="horizontal-layout">
          <img className="icon-filtered" src={infoIcon} alt="About" />
          <h3 id="about_text" className="  user-title">
            About
          </h3>
        </div>
        <hr className="divider" />
        <div className="horizontal-layout-pointer" onClick={signout}>
          <img className="icon" src={logoutIcon} alt="Sign Out" />
          <h3 id="sign_out" className="  user-title">
            Sign out
          </h3>
        </div>
        <hr className="divider" />
      </div>
      <div className="version-container">
        <p id="build_version_textview" className="version-text">
          Build version: 2.0.0
        </p>
      </div>
    </div>
  );
};

export default UserDetails;
