import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PaymentTable.css"; // Import CSS file for styling if needed
import { db, auth } from "../register/firebase";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import { collection, getDoc, getDocs } from "firebase/firestore";

import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "../Signup.css";

import logogif from "../../images/logo-progress.gif";
const PaymentTable = () => {
  const [plans, setPlans] = useState([]);
  const [uid, setUid] = useState(null);
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(true); // State to control whether the dialog is open

  const [loading, setLoading] = useState(true);

  const loadPayment = async (priceId) => {
    setOpenDialog(true);
    setLoading(true);
    if (uid) {
      const body = {
        price_id: priceId,
        user_id: uid ? uid : "",
      };

      try {
        console.log("Starting to load payment...");
        const response = await axios.post(
          "https://payments.algoturtle.in/payments/create-checkout-session",
          body,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("Payment loaded:", response.data);
        window.location.href = response.data;
        return response.data;
      } catch (error) {
        console.error("Error loading payment:", error);
      } finally {
        setLoading(false);
        setOpenDialog(false);
        console.log("Payment loading process completed.");
      }
    } else {
      const searchParams = new URLSearchParams(window.location.search);
      const uidParam = searchParams.get("uid");
      if (!uidParam || !uid) {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            const uid = user.uid;
            setUid(uid);
            if (window.location.pathname === "/pricing") {
              navigate(`/pricing?uid=${user.uid}`);
            }
          } else {
            console.log("redirect required ");
            if (window.location.pathname === "/pricing") {
              navigate("/login");
            }
            console.log("redirect completed ");
          }
        });
      } else {
        console.log("NOT redirect required ");
      }
    }
  };

  const extractUidFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const uidParam = searchParams.get("uid");
    setUid(uidParam);
    if (uidParam === null) {
      navigate("/login");
    }

    if (!(window.location.pathname === "/pricing")) {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  useEffect(() => {
    extractUidFromUrl();

    const fetchAdditionalDetails = async () => {
      try {
        const priceInfoUrls = [
          // "https://payments.algoturtle.in/product/price_info?id=price_1Ooh9eSFyRA0FGivTgB9dJEE",
          "https://payments.algoturtle.in/product/price_info?id=price_1OzdvgSFyRA0FGivwE96sBRE",
          "https://payments.algoturtle.in/product/price_info?id=price_1Ooh9KSFyRA0FGivFVqJTuxu",
        ];

        const promises = priceInfoUrls.map(async (url) => {
          const response = await fetch(url);
          const data = await response.json();
          const price = data.unit_amount_decimal / 100; // Adjust as needed
          const prev_price = data.plan_type === "basic" ? 7000 : 21000;
          const discount = ((prev_price - price) / prev_price) * 100;
          return {
            name: data.plan_type, // Adjust as needed
            price: price,
            priceId: data.id, // Adjust as needed
            benefits: Object.values(data.metadata),
            benefitsMetadata: data,
            prev_price: prev_price,
            discount: discount.toFixed(2),
          };
        });

        const fetchedPlans = await Promise.all(promises);
        setPlans(fetchedPlans);
        setLoading(false);
        setOpenDialog(false);
      } catch (error) {
        console.error("Error fetching additional details:", error);
      }
    };

    fetchAdditionalDetails();
  }, [extractUidFromUrl]);

  return (
    <div>
      <Navbar />

      <div className="pt">
        <div class="pricingTable">
          <h2 className="pricingTable-title">
            Find a plan that's right for you.
          </h2>

          <ul class="pricingTable-firstTable">
            {/* <li class="pricingTable-firstTable_table">
            <h1 class="pricingTable-firstTable_table__header">
              {plans[0].name}
            </h1>
            <p class="pricingTable-firstTable_table__pricing">
              <span>$</span>
              <span>10</span>
              <span>Month</span>
            </p>
            <ul class="pricingTable-firstTable_table__options">
              <li>Unlimited Listing</li>
              <li>Edit Your Listing</li>
              <li>Approve Reviews</li>
            </ul>
            <button class="pricingTable-firstTable_table__getstart">
              Get Started Now
            </button>
          </li>*/}
            {plans.map((plan) => (
              <li class="pricingTable-firstTable_table">
                <p className="discount-badge-trail">
                  <span className="discount-badge-span">
                    7 days free trail{" "}
                  </span>
                </p>
                <p className="discount-badge">
                  <span className="discount-badge-span">
                    {plan.discount} % off{" "}
                  </span>
                </p>
                <h1 class="pricingTable-firstTable_table__header">
                  {plan.name}
                </h1>
                <p class="pricingTable-firstTable_table__pricing">
                  <span>Rs. </span>
                  <span>{plan.price}</span>
                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "5px",
                      textDecoration: "line-through",
                    }}
                  >
                    {plan.prev_price}
                  </span>

                  <span>Month</span>
                </p>
                <ul class="pricingTable-firstTable_table__options">
                  {plan.benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
                <button
                  class="pricingTable-firstTable_table__getstart"
                  onClick={() => loadPayment(plan.priceId)}
                >
                  Get Started Now
                </button>
              </li>
            ))}

            {openDialog && (
              <div className="dialog-content" style={{ margin: "auto" }}>
                <img src={logogif} alt="Loading" className="logo-gif" />
                <p>{loading ? "Processing..." : "Processing..."}</p>
              </div>
            )}
            {/* <li class="pricingTable-firstTable_table">
            <h1 class="pricingTable-firstTable_table__header">
              Platinum Packege
            </h1>
            <p class="pricingTable-firstTable_table__pricing">
              <span>$</span>
              <span>49</span>
              <span>Month</span>
            </p>
            <ul class="pricingTable-firstTable_table__options">
              <li>Unlimited Listing</li>
              <li>Edit Your Listing</li>
              <li>Approve Reviews</li>
            </ul>
            <button class="pricingTable-firstTable_table__getstart">
              Get Started Now
            </button>
          </li> */}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentTable;
