import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updatePhoneNumber,
} from "firebase/auth";
import "./Signup.css";
import logogif from "../images/logo-progress.gif";
import successGif from "../images/logo-long.jpg";

import {
  Button,
  TextField,
  LinearProgress,
  Typography,
  Container,
} from "@material-ui/core";

import { db } from "./register/firebase";
import { doc, setDoc, collection, updateDoc } from "firebase/firestore";

const Signup = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const [signupMethod, setSignupMethod] = useState("email");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const [accountcreated, setAccountcreated] = useState();
  const [userCredential, setuserCredential] = useState();

  // phone number verification

  const START_TIME_IN_MILLIS = 60000; // 1 minute
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [forceResendingToken, setForceResendingToken] = useState(null);
  const [timeLeft, setTimeLeft] = useState(START_TIME_IN_MILLIS);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appVerifier, setappVerifier] = useState();

  useEffect(() => {
    let timer;
    if (isTimerRunning) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 0) {
            clearInterval(timer);
            setIsTimerRunning(false);
            return 0;
          }
          return prevTimeLeft - 1000;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isTimerRunning]);

  const startTimer = () => {
    setTimeLeft(START_TIME_IN_MILLIS);
    setIsTimerRunning(true);
  };

  const handleSendOtp = () => {
    console.log("on send otp");
    setLoading(true);
    const phoneNumberWithCountryCode = "+91" + phoneNumber;

    const appVerifier2 = window.recaptchaVerifier;

    const formatPh = "+91" + phoneNumber;
    // signInWithPhoneNumber;

    signInWithPhoneNumber(auth, formatPh, appVerifier2)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        // setShowOTP(true);
        setVerificationId(confirmationResult.verificationId);
        setForceResendingToken(confirmationResult.forceResendingToken);
        // toast.success("OTP sended successfully!");

        console.log("on OTP sended successfully!");
        saveUserDetailsToFirestore();
        setShowOtpInput(true);

        setLoading(false);
        setOpenDialog(false);
      })

      .catch((error) => {
        setLoading(false);
        setOpenDialog(false);
        console.log("on send OTP error!" + error);

        console.log(error);
        setLoading(false);
        alert(`Verification failed: ${error.message}`);
      });
  };

  const onCaptchVerify = () => {
    console.log("on captch verify");
    if (!window.recaptchaVerifier) {
      console.log("on captch verify in side if ");
      setLoading(false); // Start loading indicator
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
          "expired-callback": () => {},
        }
      );

      setappVerifier(window.recaptchaVerifier);

      handleSendOtp();
    } else {
      console.log("on captch verify in side else  ");
      setappVerifier(window.recaptchaVerifier);
      handleSendOtp();
    }
  };

  const handleResendOtp = () => {
    console.log("on re send otp");
    if (!forceResendingToken) return;
    setLoading(true);

    const phoneNumberWithCountryCode = "+91" + phoneNumber;
    auth
      .signInWithPhoneNumber(
        phoneNumberWithCountryCode,
        appVerifier,
        forceResendingToken
      )
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        setLoading(false);
        startTimer();
        showOtpInput(true);
        alert("OTP sent successfully");
      })
      .catch((error) => {
        showOtpInput(false);
        setLoading(false);
        alert(`Verification failed: ${error.message}`);
      });
  };

  const handleVerifyOtp = () => {
    console.log("on verify otp");
    setLoading(true);
    setOpenDialog(true);
    const credential = PhoneAuthProvider.credential(verificationId, otp);

    const user = auth.currentUser;
    if (user) {
      updatePhoneNumber(user, credential)
        .then(() => {
          //  saveUserDetailsToFirestore(true);
          setLoading(false);
          alert("Sign-up successful!");
          navigate(`/pricing?uid=${userCredential.user.uid}`);
          // Navigate to another component/page if needed
          updatePhoneNumberDetailsFirstore(true);
          setLoading(false);
          setOpenDialog(false);
        })
        .catch((error) => {
          setLoading(false);
          setOpenDialog(false);
          alert(`Verification failed: ${error.message}`);
        });
    }
  };

  const handleEmailPasswordSignup = async () => {
    setLoading(true);
    setOpenDialog(true);
    try {
      const userCredentialauth = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      setuserCredential(userCredentialauth);
      // setAccountcreated(true);
      onCaptchVerify();
      //  navigate(`/pricing?uid=${userCredential.user.uid}`);
    } catch (error) {
      setLoading(false);
      setOpenDialog(false);
      console.error("Error signing up with email/password:", error);
      alert(
        "Error signing up with email/password. Please try again.\n " +
          error.message
      );
      if (error.message.includes("auth/email-already-in-use")) {
        navigate("/login");
      }
    }
  };

  // till here auth
  const handleSignupMethodChange = (method) => {
    setSignupMethod(method);
    setShowOtpInput(false);
  };

  const handleSignup = () => {
    if (signupMethod === "email") {
      handleEmailPasswordSignup();
    }
  };

  const saveUserDetailsToFirestore = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        const userDetails = {
          name: name,
          phoneNumber: phoneNumber,
          isPhoneVerified: false,
          email: email,
          isValid: false,
          plan_type: "payment_pending",
          isPhoneVerified: false,
        };

        await setDoc(userDoc, userDetails);
        console.log("User details saved to Firestore successfully");

        const brokerDoc = doc(
          collection(db, "users", user.uid, "brokers"),
          "NEO"
        );
        const brokerDetails = {
          broker_type: "Kotak Neo",
        };

        await setDoc(brokerDoc, brokerDetails);

        const mockBrokerDoc = doc(
          collection(db, "users", user.uid, "brokers"),
          "MOCK_BROKER"
        );
        const mockBrokerDetails = {
          broker_type: "Mock_Broker",
          broker_metadata: {},
        };

        await setDoc(mockBrokerDoc, mockBrokerDetails);
      } else {
        console.error("No user is signed in");
      }
    } catch (error) {
      console.error("Error saving user details to Firestore", error);
    }
  };

  const updatePhoneNumberDetailsFirstore = async (isPhoneVerified) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        await updateDoc(userDoc, {
          isPhoneVerified: isPhoneVerified,
          phoneNumber: phoneNumber,
        });
        alert("Number verified");
      } else {
        console.error("No user is signed in");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <h1 className="signup-header">Sign Up</h1>

        <TextField
          type="text"
          label="Name"
          value={name}
          margin="dense"
          variant="outlined"
          fullWidth
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          type="email"
          label="Email"
          value={email}
          margin="normal"
          fullWidth
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          type="password"
          label="Password"
          value={password}
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          label="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        {!showOtpInput && (
          <button className="signup-button" onClick={handleSignup}>
            Send OTP
          </button>
        )}
        {showOtpInput && (
          <div>
            <TextField
              label="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerifyOtp}
              disabled={loading}
              fullWidth
            >
              Verify OTP
            </Button>
            <p
              onClick={() => {
                handleSendOtp();
              }}
              className="resend-otp"
            >
              {" "}
              Resend OTP
            </p>
          </div>
        )}
        <div id="recaptcha-container"></div>
        <div className="do-not-have-account">
          <p> have an accocunt </p>

          <a href="/login"> Login</a>
        </div>
        {openDialog && (
          <div className="dialog">
            <div className="dialog-content">
              <img src={logogif} alt="Loading" className="logo-gif" />
              <p>{loading ? "Processing..." : "Processing..."}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
