import React, { useEffect } from "react";
import "./QrCards.css";
import playstoreImg from "../images/playstore.png";
import emailImg from "../images/email.png";
import phoneImg from "../images/phone.png";
import linkedinImg from "../images/linkedIn.png";

import instaImg from "../images/insta.png";
import telegramImg from "../images/telegram.png";
import youtubeImg from "../images/youtube.png";
import facebookImg from "../images/facebook.png";
import xImg from "../images/twitter.png";
import addImg from "../images/add.png";
import logoImg from "../images/logo-long.jpg";
import webImg from "../images/web-icon.png";

function QrCards() {
  const downloadVCard = () => {
    // Define vCard data
    const vCardData = `BEGIN:VCARD
VERSION:3.0
N:AlgoTurtle
FN:AlgoTurtle
ORG:AlgoTurtle
TITLE:Company
TEL;TYPE=CELL,VOICE:+91 8920044149
URL;TYPE=WORK:https://algoturtle.co.in/
URL;TYPE=INSTAGRAM:https://www.instagram.com/algoturtle
URL;TYPE=https://www.youtube.com/@algoTurtle
URL;TYPE=PLAYSTORE:https://play.google.com/store/apps/details?id=com.algoturtle
EMAIL;TYPE=INTERNET:santoshsinghalgoturtle@gmail.com
END:VCARD`;

    // Create a Blob from vCard data
    const blob = new Blob([vCardData], { type: "text/vcard" });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "AlgoTurtle.vcf"); // Set download attribute with desired file name
    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="card-main">
      <>
        <div className="header-container">
          <img src={logoImg} className="img-fluid" alt="Logo" />
          <div className="container-title-add">
            <div className="title-con">
              <h1 className="card-details-container-title">
                <span style={{ color: "#2D3192" }}>algo</span>
                <span style={{ color: "#008000" }}>Turtle</span>
              </h1>
            </div>
            <img src={addImg} alt="Add to Contact" onClick={downloadVCard} />
          </div>
        </div>

        <div className="card-details-container">
          {/* mail */}
          <div class="card">
            <div class="card-content">
              <a href="mailto:support@algoturtle.com" className="icon-link">
                <img src={emailImg} alt="Email Icon" class="icon" />
              </a>
              <div>
                <p class="text">Send me an email</p>
                <a
                  href="mailto:santoshsinghalgoturtle@gmail.com "
                  class="link card-a"
                >
                  Mail: support@algoturtle.com
                </a>
              </div>
            </div>
          </div>
          {/* phone */}
          <div class="card">
            <div class="card-content">
              <a href="tel:8920044149">
                <img src={phoneImg} class="icon" />
              </a>
              <div>
                <p class="text">Give me a call</p>
                <a href="tel:8920044149" class="link card-a">
                  Phone: 8920044149
                </a>
              </div>
            </div>
          </div>
          {/* playstore */}
          <div class="card">
            <div class="card-content">
              <a
                href="https://play.google.com/store/apps/details?id=com.proalgoturtle"
                className="icon-link"
              >
                <img src={playstoreImg} alt="Play Store Icon" class="icon" />
              </a>
              <div>
                <p class="text">Download from Play Store</p>
                <a
                  href="https://play.google.com/store/apps/details?id=com.proalgoturtle"
                  class="link card-a"
                >
                  Google Play Store
                </a>
              </div>
            </div>
          </div>

          {/* website */}
          <div class="card">
            <div class="card-content">
              <a href="https://algoturtle.co.in/" className="icon-link">
                <img src={webImg} alt="Play Store Icon" class="icon" />
              </a>
              <div>
                <p class="text">Explore our website</p>
                <a href="https://algoturtle.co.in/" class="link card-a">
                  website : AlgoTurtle
                </a>
              </div>
            </div>
          </div>

          {/* LinkedIn */}
          {/* <div class="card">
          <div class="card-content">
            <img src={linkedinImg} alt="LinkedIn Icon" class="icon" />
            <div>
              <p class="text">Connect on LinkedIn</p>
              <a href="https://www.linkedin.com/in/AlgoTurtle/" class="link">
                LinkedIn: AlgoTurtle
              </a>
            </div>
          </div>
        </div> */}

          {/* Instagram */}
          <div class="card">
            <div class="card-content">
              <a
                href="https://www.instagram.com/AlgoTurtle/"
                className="icon-link"
              >
                <img src={instaImg} alt="Instagram Icon" class="icon" />
              </a>
              <div>
                <p class="text">Follow me on Instagram</p>
                <a
                  href="https://www.instagram.com/AlgoTurtle/"
                  class="link card-a"
                >
                  Instagram: AlgoTurtle
                </a>
              </div>
            </div>
          </div>
          {/*Telegram */}
          {/* <div class="card">
          <div class="card-content">
            <img src={telegramImg} alt="Telegram Icon" class="icon" />
            <div>
              <p class="text">Join me on Telegram</p>
              <a href="https://t.me/AlgoTurtle" class="link">
                Telegram: AlgoTurtle
              </a>
            </div>
          </div>
        </div> */}
          {/* You tube */}
          <div class="card">
            <div class="card-content">
              <a
                href="https://www.youtube.com/@algoTurtle"
                className="icon-link"
              >
                <img src={youtubeImg} alt="YouTube Icon" class="icon" />
              </a>
              <div>
                <p class="text">Subscribe on YouTube</p>
                <a
                  href="https://www.youtube.com/@algoTurtle"
                  class="link card-a"
                >
                  YouTube: AlgoTurtle
                </a>
              </div>
            </div>
          </div>
          {/*Facebook*/}
          {/* <div class="card">
          <div class="card-content">
            <img src={facebookImg} alt="facebook" class="icon" />
            <div>
              <p class="text">Follow on Facebook</p>
              <a
                href="https://play.google.com/store/apps/details?id=com.AlgoTurtle"
                class="link"
              >
                Facebook: AlgoTurtle
              </a>
            </div>
          </div>
        </div> */}
          {/*Twitter*/}
          {/* <div class="card">
          <div class="card-content">
            <img src={xImg} alt="Play Store Icon" class="icon" />
            <div>
              <p class="text">Follow on X</p>
              <a
                href="https://play.google.com/store/apps/details?id=com.AlgoTurtle"
                class="link"
              >
                X: AlgoTurtle
              </a>
            </div>
          </div>
        </div> */}

          <div className="additional-content">
            <button className="add-to-contact-btn" onClick={downloadVCard}>
              Save as contact
            </button>
          </div>
        </div>
      </>
    </div>
  );
}

export default QrCards;
