import React, { useEffect, useState } from "react";
import "./KotakNeoForm.css"; // Import the CSS file
import neoIcon from "../images/kotak_neo_img.jpg";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import StrategyService from "../services/StrategyService";
import { Alert } from "react-bootstrap";
import { db, auth } from "../components/register/firebase";
const KotakNeoForm = ({ broker }) => {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [mpin, setMpin] = useState("");
  const [consumerKey, setConsumerKey] = useState("");
  const [consumerSecret, setConsumerSecret] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [brokerDetails, setBrokerDetails] = useState(null); // Initialize with null or appropriate initial value

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    StrategyService.fetchBrokerDetails(
      broker.id,
      (mbrokerDetails) => {
        console.log(mbrokerDetails);
        setBrokerDetails(mbrokerDetails);
        const metadata = mbrokerDetails.broker_metadata;
        if (metadata != null) {
          setMobile(
            metadata.mobile_number.substring(3)
              ? metadata.mobile_number.substring(3)
              : ""
          );
          setPassword(metadata.password || "");
          setMpin(metadata.mpin || "");
          setConsumerKey(metadata.consumer_key || "");
          setSessionToken(metadata.session_token || null);
          setConsumerSecret(metadata.consumer_secret || "");
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const saveBroker = async () => {
    const user = auth.currentUser; // Get current user
    const userId = user ? user.uid : ""; // Extract user ID
    const num = "+91" + mobile;
    const requestBody = {
      user_id: userId,
      broker_data: {
        broker_type: "Kotak Neo",
        broker_metadata: {
          mobile_number: num,
          password: password,
          mpin: mpin,
          consumer_key: consumerKey,
          consumer_secret: consumerSecret,
          user_id: userId,
        },
      },
    };
    setLoading(true);
    StrategyService.saveBroker(
      requestBody,
      (response) => {
        console.log("saved broker ");
        console.log(response);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      }
    );
  };

  return (
    <div className="neo-Container">
      <div>
        <h1 className="HeaderText">Broker Details</h1>
      </div>

      <div className="ScrollContainer">
        <div className="ProfileContainer">
          <img className="ProfileImage" src={neoIcon} alt="Kotak Neo" />
          <h2 className="ProfileName">Kotak Neo</h2>
        </div>

        <input
          className="Input"
          type="text"
          placeholder="Enter mobile number"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
        <input
          className="Input"
          type="password"
          placeholder="Enter your Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className="Input"
          type="text"
          placeholder="Enter mpin"
          value={mpin}
          onChange={(e) => setMpin(e.target.value)}
        />
        <input
          className="Input"
          type="text"
          placeholder="Enter your Consumer Key"
          value={consumerKey}
          onChange={(e) => setConsumerKey(e.target.value)}
        />
        <input
          className="Input"
          type="text"
          placeholder="Enter your Consumer Secret"
          value={consumerSecret}
          onChange={(e) => setConsumerSecret(e.target.value)}
        />
        <input
          className="Input"
          type="text"
          placeholder="Enter Session Token"
          value={JSON.stringify(sessionToken)}
          onChange={(e) => setSessionToken(e.target.value)}
        />
      </div>
      {sessionToken && (
        <div className="SaveButtonContainer" onClick={saveBroker}>
          <span className="SaveButtonText">Activate</span>
        </div>
      )}
      {!sessionToken && (
        <div className="SaveButtonContainer" onClick={saveBroker}>
          <span className="SaveButtonText">SAVE</span>
        </div>
      )}
      <Dialog open={loading}>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <p>{"Saving Broker..."}</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default KotakNeoForm;
