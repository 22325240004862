import React, { useEffect, useRef } from "react";
import star from "../images/star.png"; // Adjust this import to the correct path
import "./FeedbackContainer.css"; // CSS file for styling

const FeedbackContainer = () => {
  const feedbackSubConRef = useRef(null);
  const feedbackCardRefs = useRef([]);
  const scrollIntervalRef = useRef(null);

  const feedbackData = [
    {
      name: "Shalini Singh",
      description:
        "AlgoTurtle delivers an exceptional app experience with its effective strategies, ensuring consistent and profitable outcomes in the long run.",
    },
    {
      name: "Pragti Kabra",
      description:
        "Experience stellar service and discover profitable solutions with AlgoTurtle's innovative approach and reliable strategies.",
    },
    // Add more feedback data as needed
  ];

  useEffect(() => {
    const feedbackSubCon = feedbackSubConRef.current;

    let scrollAmount = 0;

    const scrollContent = () => {
      scrollAmount += 1;
      if (scrollAmount >= feedbackSubCon.scrollWidth / 2) {
        scrollAmount = 0; // Reset scroll amount to loop
      }
      feedbackSubCon.scrollLeft = scrollAmount;
    };

    const startScrolling = () => {
      scrollIntervalRef.current = setInterval(scrollContent, 20);
    };

    const stopScrolling = () => {
      clearInterval(scrollIntervalRef.current);
    };

    const handleUserScroll = () => {
      stopScrolling();
      scrollIntervalRef.current = setTimeout(startScrolling, 2000);
    };

    feedbackSubCon.addEventListener("scroll", handleUserScroll);
    startScrolling();

    // Intersection Observer to detect when the section is in view
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            feedbackCardRefs.current.forEach((card) => {
              card.classList.add("show");
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(feedbackSubCon);

    return () => {
      clearInterval(scrollIntervalRef.current);
      feedbackSubCon.removeEventListener("scroll", handleUserScroll);
      observer.disconnect();
    };
  }, []);

  return (
    <div className="feedback-container">
      <h1 className="title-hme-feed">Customer Feedback</h1>
      <div className="feedback-sub-con" ref={feedbackSubConRef}>
        {feedbackData.map((feedback, index) => (
          <div
            className="feedback-card"
            key={index}
            ref={(el) => feedbackCardRefs.current.push(el)}
          >
            <div className="star-customer-container">
              <div>
                <img src={star} className="start-icon" alt="star" />
                <img src={star} className="start-icon" alt="star" />
                <img src={star} className="start-icon" alt="star" />
                <img src={star} className="start-icon" alt="star" />
                <img src={star} className="start-icon" alt="star" />
              </div>
              <h4 className="customer-name">- {feedback.name}</h4>
            </div>
            <div className="feedback-description">
              <h3>Exceptional App with Effective Strategies</h3>
              <p>{feedback.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeedbackContainer;
