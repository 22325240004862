import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { doc, setDoc, collection, updateDoc } from "firebase/firestore";
import {
  Button,
  TextField,
  LinearProgress,
  Typography,
  Container,
} from "@material-ui/core";

import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  updatePhoneNumber,
} from "firebase/auth";
import { getAuth } from "firebase/auth";

import { db } from "./register/firebase";

import logogif from "../images/logo-progress.gif";
import "./PhoneNumberVerificationPage.css";
import { tr } from "date-fns/locale";
const PhoneNumberVerificationPage = () => {
  const [userCredential, setuserCredential] = useState();
  const navigate = useNavigate();
  const START_TIME_IN_MILLIS = 60000; // 1 minute
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [forceResendingToken, setForceResendingToken] = useState(null);
  const [timeLeft, setTimeLeft] = useState(START_TIME_IN_MILLIS);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appVerifier, setappVerifier] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    let timer;
    if (isTimerRunning) {
      timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 0) {
            clearInterval(timer);
            setIsTimerRunning(false);
            return 0;
          }
          return prevTimeLeft - 1000;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isTimerRunning]);

  const startTimer = () => {
    setTimeLeft(START_TIME_IN_MILLIS);
    setIsTimerRunning(true);
  };

  const handleSendOtp = () => {
    console.log("on send otp");
    setLoading(true);
    setOpenDialog(true);
    const phoneNumberWithCountryCode = "+91" + phoneNumber;

    const appVerifier2 = window.recaptchaVerifier;

    const formatPh = "+91" + phoneNumber;
    // signInWithPhoneNumber;

    signInWithPhoneNumber(auth, formatPh, appVerifier2)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setOpenDialog(false);
        // setShowOTP(true);
        setVerificationId(confirmationResult.verificationId);
        setForceResendingToken(confirmationResult.forceResendingToken);
        // toast.success("OTP sended successfully!");
        setOtpSent(true);
        console.log("on OTP sended successfully!");
      })
      .catch((error) => {
        console.log("on send OTP error!" + error);
        setOtpSent(false);
        console.log(error);
        setLoading(false);
        setOpenDialog(false);
        alert(`Verification failed: ${error.message}`);
      });
  };

  const onCaptchVerify = () => {
    setOpenDialog(true);
    console.log("on captch verify");
    if (!window.recaptchaVerifier) {
      console.log("on captch verify in side if ");
      setLoading(false); // Start loading indicator
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
          "expired-callback": () => {},
        }
      );

      setappVerifier(window.recaptchaVerifier);

      handleSendOtp();
    } else {
      setOpenDialog(false);
      console.log("on captch verify in side else  ");
      setappVerifier(window.recaptchaVerifier);
      handleSendOtp();
    }
  };

  const handleResendOtp = () => {
    console.log("on re send otp");
    if (!forceResendingToken) return;
    setLoading(true);

    setOpenDialog(true);

    const phoneNumberWithCountryCode = "+91" + phoneNumber;
    auth
      .signInWithPhoneNumber(
        phoneNumberWithCountryCode,
        appVerifier,
        forceResendingToken
      )
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        setLoading(false);
        startTimer();
        setOtpSent(true);
        setOpenDialog(false);
        alert("OTP sent successfully");
      })
      .catch((error) => {
        setOtpSent(false);
        setLoading(false);
        setOpenDialog(false);
        alert(`Verification failed: ${error.message}`);
      });
  };

  const handleVerifyOtp = () => {
    console.log("on verify otp");
    setLoading(true);

    setOpenDialog(true);
    const credential = PhoneAuthProvider.credential(verificationId, otp);

    const user = auth.currentUser;
    if (user) {
      updatePhoneNumber(user, credential)
        .then(() => {
          //  saveUserDetailsToFirestore(true);
          setLoading(false);
          setOpenDialog(false);
          updatePhoneNumberDetailsFirstore();
          // Navigate to another component/page if needed
        })
        .catch((error) => {
          setLoading(false);
          setOpenDialog(false);
          alert(`Verification failed: ${error.message}`);
        });
    }
  };

  const updatePhoneNumberDetailsFirstore = async (isPhoneVerified) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        await updateDoc(userDoc, {
          isPhoneVerified: isPhoneVerified,
          phoneNumber: phoneNumber,
        });
        alert("Number verified");
      } else {
        console.error("No user is signed in");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  // const saveUserDetailsToFirestore = (isPhoneVerified) => {
  //   const firestore = firebase.firestore();
  //   const uid = firebase.auth().currentUser.uid;
  //   firestore
  //     .collection("users")
  //     .doc(uid)
  //     .update({ isPhoneVerified })
  //     .then(() => alert("Number verified"))
  //     .catch((error) => alert(`Error: ${error.message}`));
  // };

  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60000);
    const seconds = Math.floor((timeLeft % 60000) / 1000);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="verify-phone-container">
      <h5 className="title">Verify Phone Number</h5>
      <TextField
        label="Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      {!otpSent && (
        <Button
          id="send-otp-button"
          variant="contained"
          color="primary"
          onClick={onCaptchVerify}
          disabled={loading || isTimerRunning}
          fullWidth
        >
          Send OTP
        </Button>
      )}
      {isTimerRunning && (
        <Typography variant="body2">
          Resend OTP in {formatTimeLeft()}
        </Typography>
      )}

      {otpSent && (
        <div>
          <TextField
            label="OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerifyOtp}
            disabled={loading}
            fullWidth
          >
            Verify OTP
          </Button>
          {/* <Button
            id="resend-otp-button"
            variant="contained"
            color="secondary"
            onClick={handleResendOtp}
            disabled={loading || isTimerRunning}
            fullWidth
          >
            Resend OTP
          </Button> */}

          <p
            onClick={() => {
              handleSendOtp();
            }}
            className="resend-otp"
          >
            {" "}
            Resend OTP
          </p>
        </div>
      )}
      {openDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <img src={logogif} alt="Loading" className="logo-gif" />
            <p>{loading ? "Processing..." : "Processing..."}</p>
          </div>
        </div>
      )}
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default PhoneNumberVerificationPage;
