import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../images/logo.jpg";
import menuIcon from "../images/menuIcon.png"; // Replace with your menu icon image

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [activeItem, setActiveItem] = useState(null); // State to track active item

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      showButton();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleItemClick = (index) => {
    setActiveItem(index); // Set active item index
    closeMobileMenu(); // Close menu on item click
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <a href="/">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <span style={{ color: "#2D3192" }}>algo</span>
            <span style={{ color: "#008000" }}>Turtle</span>
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <img
              src={menuIcon} // Use your menu icon image here
              alt="Menu"
              className="menu-img"
            />
          </div>
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              className={`nav-links ${activeItem === 0 ? "active" : ""}`}
              onClick={() => handleItemClick(0)}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/dashboard"
              className={`nav-links ${activeItem === 1 ? "active" : ""}`}
              onClick={() => handleItemClick(1)}
            >
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/pricing"
              className={`nav-links ${activeItem === 2 ? "active" : ""}`}
              onClick={() => handleItemClick(2)}
            >
              Pricing
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/blogs"
              className={`nav-links ${activeItem === 3 ? "active" : ""}`}
              onClick={() => handleItemClick(3)}
            >
              Blogs
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/about-us"
              className={`nav-links ${activeItem === 4 ? "active" : ""}`}
              onClick={() => handleItemClick(4)}
            >
              About us
            </Link>
          </li>
          <li>
            <Link
              to="/register"
              className="nav-links-mobile"
              onClick={closeMobileMenu}
            >
              Build strategy
            </Link>
          </li>
        </ul>
        {button && (
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
          >
            <Link to="/register" className="links" onClick={closeMobileMenu}>
              Build strategy
            </Link>
          </Button>
        )}
      </nav>
    </>
  );
}

export default Navbar;
