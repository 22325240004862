import React, { useState, useEffect } from "react";
import axios from "axios";
import { auth } from "./register/firebase"; // Ensure you have Firebase authentication initialized properly
import "./RunStrategyActivity.css"; // Import your CSS file

import StrategyService from "../services/StrategyService";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import UserService from "../services/UserService";

const RunStrategyActivity = (mstrategy, strategyType) => {
  // const location = useLocation();
  // const mstrategy = location.state.strategies[0] || {};

  // Define state for each input

  const navigate = useNavigate();
  mstrategy = mstrategy.mstrategy;
  const [strategyName, setStrategyName] = useState(mstrategy.strategyName);
  const [startTime, setStartTime] = useState("09:15");
  const [endTime, setEndTime] = useState("15:20");
  const [initialCapital, setInitialCapital] = useState(
    mstrategy.initialCapital
  );
  const [maxLoss, setMaxLoss] = useState(mstrategy.maxLossPercentage);
  const [maxLots, setMaxLots] = useState(mstrategy.maxLots);
  const [profitRangeMax, setProfitRangeMax] = useState(
    mstrategy.maxProfitPercentage
  );
  const [profitRangeMin, setProfitRangeMin] = useState(
    mstrategy.minProfitPercentage
  );
  const [broker, setBroker] = useState("");
  const [stockName, setStockName] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const [stocks, setStocks] = useState([]);

  const [brokers, setBrokers] = useState([]);

  const [defaultBroker, setDefaultBroker] = useState("");

  const [defaultBrokerType, setDefaultBrokerType] = useState("");

  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [uid, setuid] = useState(
    auth.currentUser ? auth.currentUser.uid : null
  );

  const handleClose = () => {
    setShowDialog(false);
  };

  const fetchStocksFromAPI = () => {
    StrategyService.getStocks(
      (data) => {
        setStocks(data);
        setStockName(data[0]);
      },
      (error) => {
        console.error("Error fetching stocks:", error);
        alert("Error: " + error);
        // Handle error if needed
      }
    );
  };
  const fetchBrokers = () => {
    StrategyService.fetchBrokers(
      (brokers) => {
        setBrokers(brokers);
        setDefaultBrokerType(brokers.length > 0 ? brokers[0].broker_type : "");
        setDefaultBroker(brokers.length > 0 ? brokers[0].id : ""); // Set default broker ID to the first broker's ID
      },
      (error) => {
        alert("Error: " + error);
      }
    );
  };

  const handleBrokerChange = (e) => {
    setDefaultBroker(e.target.value);
    console.log(e);
    for (const b of brokers) {
      if (e.target.value == b.id) {
        console.log(b.broker_type);
        setDefaultBrokerType(b.broker_type);
        break;
      } else {
        console.log("no match");
      }
    }
  };
  useEffect(() => {
    fetchStocksFromAPI();
    fetchBrokers();
  }, []);
  const handleSubmit = async () => {
    setLoading(true);
    setOpenDialog(true);

    const list = await new Promise((resolve, reject) => {
      StrategyService.fetchAllRunning(
        (data) => resolve(data),
        (error) => reject(error)
      );
    });
    const userDetails = await UserService.getUserDetails(uid);

    if (
      userDetails.plan_type != "pro" ||
      (strategyType == "pro" && list.length >= 10)
    ) {
      alert("You have reached to maximum limit ");
    } else if (userDetails.plan_type != "pro" && list.length >= 4) {
      alert("Basic User can run max 4 strategies");
      navigate(`/upgrade-to-pro?uid=${uid}`);
    } else {
      try {
        const user = auth.currentUser;
        if (!user) {
          throw new Error("User is not authenticated");
        }

        const userId = user.uid;
        const jsonObject = {
          user_id: auth.currentUser.uid,
          strategy_metadata: {
            strategy_name: strategyName,
            display_name: strategyName.replace("_", " "),
            exit: "2:30",
            interval: "2",
            start_time: startTime,
            stop_time: endTime,
            initial_capital: parseInt(initialCapital),
            additional_metadata: {
              max_loss: {
                key: "maximum_loss",
                value: parseInt(
                  (parseInt(maxLoss) / 100) * parseInt(initialCapital)
                ),
                type: "int",
                description: "max_loss",
                display_name: "max_loss",
              },
              max_lots: {
                key: "max_lots",
                value: parseInt(maxLots),
                type: "int",
                description: "max_lots",
                display_name: "max_lots",
              },
              profit_range_max: {
                key: "profit_range_max",
                value: /*parseInt(profitRangeMax),*/ parseInt(
                  (parseInt(profitRangeMax) / 100) * parseInt(initialCapital)
                ),
                type: "int",
                description: "profit_range_max",
                display_name: "profit_range_max",
              },
              profit_range_min: {
                key: "profit_range_min",
                value: /* parseInt(profitRangeMin),*/ parseInt(
                  (parseInt(profitRangeMin) / 100) * parseInt(initialCapital)
                ),
                type: "int",
                description: "profit_range_min",
                display_name: "profit_range_min",
              },
            },
          },
          broker: defaultBroker,
          broker_type: defaultBrokerType,
          stocks: [stockName],
        };

        StrategyService.runStrategy(
          jsonObject,
          (data) => {
            // Success callback
            setDialogMessage("Success: " + JSON.stringify(data));
            alert("Success: " + JSON.stringify(data));

            setOpenDialog(false);
            setLoading(false);
          },
          (error) => {
            // Failure callback
            setDialogMessage("Error: " + error.message);

            alert("Error: " + error.message);
            setOpenDialog(false);
            setLoading(false);
          }
        );
      } catch (error) {
        console.error("Error submitting mstrategy:", error);
      }
    }
  };

  return (
    <div className="container-run">
      {/*
      <h4 className="subheading">mstrategy name</h4>
      
      <div className="input-container">
        <input
          type="text"
          value={strategyName}
          onChange={(e) => setStrategyName(e.target.value)}
          placeholder="mstrategy Name"
        />
      </div> */}
      <div
        style={{
          display: "flex",
          margin: "8px",
          justifyContent: "space-between",
        }}
      >
        <div>
          {" "}
          <h4 className="subheading">Broker</h4>
          <div className="input-container">
            <select
              value={defaultBroker}
              onChange={handleBrokerChange} // Handle selection change
            >
              <option value="">Select Broker</option>
              {brokers.map((broker) => (
                <option key={broker.id} value={broker.id}>
                  {broker.id}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          {" "}
          <h4 className="subheading">Stock</h4>
          <div className="input-container">
            <select
              value={stockName}
              onChange={(e) => setStockName(e.target.value)}
            >
              <option value="">Select Stock</option>
              {stocks.map((stock) => (
                <option key={stock} value={stock}>
                  {stock}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          margin: "8px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h4 className="subheading">Start time</h4>
          <div className="input-container">
            <input
              type="text"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              placeholder="Start Time (HH:MM)"
            />
          </div>
        </div>
        <div>
          {" "}
          <h4 className="subheading">End time</h4>
          <div className="input-container">
            <input
              type="text"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              placeholder="End Time (HH:MM)"
            />
          </div>
        </div>
      </div>
      <h4 className="subheading">Initial Capital</h4>
      <div className="input-container">
        <input
          type="number"
          value={initialCapital}
          onChange={(e) => setInitialCapital(e.target.value)}
          placeholder="Initial Capital"
        />
      </div>

      <h4 className="subheading">Max loss percentage</h4>
      <div className="input-container">
        <input
          type="number"
          value={maxLoss}
          onChange={(e) => setMaxLoss(e.target.value)}
          placeholder="Max Loss"
        />
      </div>

      <h4 className="subheading">Max lots</h4>
      <div className="input-container">
        <input
          type="number"
          value={maxLots}
          onChange={(e) => setMaxLots(e.target.value)}
          placeholder="Max Lots"
        />
      </div>

      <h4 className="subheading">Max profit percentage</h4>
      <div className="input-container">
        <input
          type="number"
          value={profitRangeMax}
          onChange={(e) => setProfitRangeMax(e.target.value)}
          placeholder="Profit Range Max"
        />
      </div>
      <h4 className="subheading">Min profit percentage</h4>
      <div className="input-container">
        <input
          type="number"
          value={profitRangeMin}
          onChange={(e) => setProfitRangeMin(e.target.value)}
          placeholder="Profit Range Min"
        />
      </div>

      <div className="run-button-container">
        <button onClick={handleSubmit}>Run</button>
      </div>
      <Dialog open={openDialog}>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <p>{"Running mstrategy..."}</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RunStrategyActivity;
