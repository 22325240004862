import React from "react";
import { Link } from "react-router-dom";
import "./Support.css"; // Import CSS file for styling

const Support = () => {
  const redirectToSupport = () => {
    window.location.href =
      "https://1029.3cx.cloud/callus/#santoshsinghalgoturt";
  };

  return (
    <div className="support-container">
      <Link to="#" onClick={redirectToSupport} className="support-link">
        Customer support
      </Link>
    </div>
  );
};

export default Support;
