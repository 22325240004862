import React, { useRef, useEffect } from "react";
import "./BrokersFragment.css";

const FullMovableDialog = ({ children, onClose }) => {
  const dialogRef = useRef(null);
  const headerRef = useRef(null);

  const handleMouseDown = (e) => {
    const dialog = dialogRef.current;
    const header = headerRef.current;

    if (!header.contains(e.target)) return;

    const shiftX = e.clientX - dialog.getBoundingClientRect().left;
    const shiftY = e.clientY - dialog.getBoundingClientRect().top;

    const onMouseMove = (e) => {
      dialog.style.left = `${e.pageX - shiftX}px`;
      dialog.style.top = `${e.pageY - shiftY}px`;
    };

    document.addEventListener("mousemove", onMouseMove);

    document.onmouseup = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.onmouseup = null;
    };
  };

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="movable-dialog-overlay">
      <div
        className="full-movable-dialog"
        ref={dialogRef}
        onMouseDown={handleMouseDown}
      >
        <div className="movable-dialog-header" ref={headerRef}>
          {/* Removed the close button */}
        </div>
        <div className="movable-dialog-content">{children}</div>
      </div>
    </div>
  );
};

export default FullMovableDialog;
