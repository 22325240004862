import React from "react";
import "./OrderItem.css"; // Import your CSS file for styling

import { auth } from "./register/firebase";
import { useState } from "react";
import icon from "../images/charts.png";
import stopIcon from "./stop_circle.png";

import StrategyService from "../services/StrategyService";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import OrderDetails from "./OrderDetails";
import FullMovableDialog from "./FullMovableDialog";

const OrderItem = ({ order }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setShowDialog(false);
  };
  const handleStopClick = () => {
    setShowDialog(true);
  };

  const handleConfirm = () => {
    setLoading(true);
    setShowDialog(false);
    setOpenDialog(true);
    // Construct the request body
    const strategy = {
      user_id: auth.currentUser.uid,
      strategy_id: order.id,
    };
    StrategyService.stopStrategy(
      strategy,
      (data) => {
        // Success callback
        setDialogMessage("Success: " + JSON.stringify(data));
        alert("Success: " + JSON.stringify(data));
        setOpenDialog(false);
        setLoading(false);
      },
      (error) => {
        // Failure callback
        setDialogMessage("Error: " + error.message);
        alert("Error: " + error.message);
        setOpenDialog(false);
        setLoading(false);
      }
    );
  };
  const getPnlColor = () => {
    if (order?.pnl === undefined || order.pnl === 0) {
      return "gray";
    } else if (order.pnl > 0) {
      return "green";
    } else {
      return "red";
    }
  };
  const [openOrderDetailsb, setOpenOrderDetails] = useState(false);

  const openOrderDetails = () => {
    setOpenOrderDetails(!openOrderDetailsb);
  };

  return (
    <div
      style={{
        backgroundColor: getPnlColor(),
        paddingBottom: "10px",
        borderRadius: "8px",
      }}
    >
      <div className="order-item-container">
        <div className="order-item" onClick={openOrderDetails}>
          <div className="icon-strategy">
            <img className="img-chart" src={icon} alt="Chart" />
            <p className="strategy">{order.strategy_name}</p>
          </div>
          <div>
            <p
              className="txt-mock"
              style={{
                backgroundColor:
                  order.strategy_status === "RUNNING" ? "green" : "red",
              }}
            >
              {" "}
              {order.strategy_status}{" "}
            </p>
          </div>
        </div>
        <div className="order-cap-broker" onClick={openOrderDetails}>
          <div>
            <p className="cap">capital</p>
            <p className="cap">Rs. {order.strategy_metadata.initial_capital}</p>
          </div>
          <div className="order-broker">
            <p className="order-broker-name">{order.broker_type}</p>
          </div>
        </div>

        <div className="stock-time" onClick={openOrderDetails}>
          <p className="stock-name">{order.stock}</p>
          <p className="stock-name">
            {StrategyService.formatDateTime(order.start_time.toDate())}
          </p>
        </div>

        <div className="order-cap-broker">
          <p className="order-details" onClick={openOrderDetails}>
            view order{" "}
          </p>
          {order.strategy_status.toLowerCase() === "running" && (
            <img className="stop" src={stopIcon} onClick={handleStopClick} />
          )}
          {order.strategy_status.toLowerCase() !== "running" && (
            <p style={{ color: getPnlColor() }}>Rs. {order.pnl}</p>
          )}
        </div>
      </div>

      {openOrderDetailsb && (
        <FullMovableDialog onClose={() => setOpenOrderDetails(null)}>
          <OrderDetails order={order} />
        </FullMovableDialog>
      )}

      {showDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <p className="dialog-message">
              Are you sure you want to stop this strategy?
            </p>
            <div className="dialog-buttons">
              <button className="dialog-button" onClick={handleClose}>
                No
              </button>
              <button
                className="dialog-button dialog-button-confirm"
                onClick={handleConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <Dialog open={openDialog}>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <p>{"Stopping strategy..."}</p>
          </div>
        </DialogContent>
      </Dialog>
      {/*       
      <div className="status">{status}</div>
      <img className="img-chart" src={icon} alt="Chart" />
      <div className="strategy-name">{strategyName}</div>
      <div className="txt-capital">Capital</div>
      <div className="txt-capital-price">{capitalPrice}</div>
      <div className="txt-mock">Mock</div>
      <div className="stock-name">{stockName}</div>
      <div className="txt-time">{time}</div>
      <div className="txt-view-order">View Orders</div>
      {isPaused && <img className="ic-pause" src={icon} alt="Pause Icon" />} */}
    </div>
  );
};

export default OrderItem;
