import { db, auth } from "../components/register/firebase";
import { format } from "date-fns";
import axios from "axios";
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import StrategyService from "./StrategyService";
import { Alert } from "react-bootstrap";
class UserService {
  static async doesUserCanRunProStrategy(uid, onSuccess, onFailure) {
    try {
      // Fetch user details
      const userDetails = await UserService.getUserDetails(uid);
      if (!userDetails) {
        throw new Error("User details not found");
      }

      // Destructure necessary fields
      const { phoneNumber, isPhoneVerified, plan_type, isValid } = userDetails;

      // Check the necessary conditions
      if (
        !phoneNumber ||
        !isPhoneVerified ||
        !phoneNumber ||
        plan_type === null ||
        plan_type !== "pro" ||
        isValid !== true
      ) {
        // onFailure("Please subscribe to continue enjoying our services!");
        throw new Error("Please subscribe to continue enjoying our services!");
      }
      const list = await new Promise((resolve, reject) => {
        StrategyService.fetchAllRunning(
          (data) => resolve(data),
          (error) => reject(error)
        );
      });

      if (list.length >= 10) {
        //  onFailure("User can run max 10 strategies");

        throw new Error("Pro user can run max 10 strategies");
      } else {
        onSuccess(userDetails);
      }
    } catch (error) {
      console.log(error + "user service  catch");
      onFailure(error.message);
    }
  }

  static async doesUserCanRunPublicStrategy(uid, onSuccess, onFailure) {
    try {
      // Fetch user details
      const userDetails = await UserService.getUserDetails(uid);
      if (!userDetails) {
        throw new Error("User details not found");
      }

      // Destructure necessary fields
      const { phoneNumber, isPhoneVerified, plan_type, isValid } = userDetails;

      // Check the necessary conditions
      if (
        !phoneNumber ||
        !isPhoneVerified ||
        plan_type === null ||
        !(plan_type === "pro" || plan_type === "basic") ||
        isValid !== true
      ) {
        throw new Error("Please subscribe to continue enjoying our services!");
      }
      // Fetch all running strategies and check the count
      const list = await new Promise((resolve, reject) => {
        StrategyService.fetchAllRunning(
          (data) => resolve(data),
          (error) => reject(error)
        );
      });

      if (plan_type === "basic" && list.length >= 4) {
        console.log("  on list 4 more  public clickd");
        throw new Error(plan_type + " User can run max 4 strategies");
      } else if (plan_type === "pro" && list.length >= 10) {
        console.log("  on list 4 more  public clickd");
        throw new Error(plan_type + " user can run max 10 strategies");
      } else {
        onSuccess(userDetails);
      }
    } catch (error) {
      onFailure(error.message);
    }
  }
  static async getUserDetails(uid) {
    try {
      const docRef = doc(db, "users", uid); // Create a document reference
      const docSnapShot = await getDoc(docRef); // Fetch the document
      if (docSnapShot.exists()) {
        return docSnapShot.data(); // Return the document data
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  }

  static async proLimit() {
    const list = await new Promise((resolve, reject) => {
      StrategyService.fetchAllRunning(
        (data) => resolve(data),
        (error) => reject(error)
      );
    });

    if (list.length >= 10) {
      console.log("  on list 4 more  public clickd");
      return false;
    } else {
      return true;
    }
  }
  static async basicLimit() {
    const list = await new Promise((resolve, reject) => {
      StrategyService.fetchAllRunning(
        (data) => resolve(data),
        (error) => reject(error)
      );
    });

    if (list.length >= 4) {
      return false;
    } else {
      return true;
    }
  }
}

export default UserService;
