import React from "react";
import "../App.css";
import { Button } from "./Button";
import "./IntroSection.css";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <div>
      <video src="/videos/intro.mp4" autoPlay loop muted />
      <div className="hero-container">
        <h1>AlgoTurtle - India's first AI based algo trading platform</h1>
        <p>What are you waiting for?</p>
        <div className="hero-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            onClick={console.log("hey")}
          >
            <Link className="links--new " to="/register">
              Get Started
            </Link>
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            onClick={console.log("hey")}
          >
            WATCH TRAILER <i className="far fa-play-circle" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
