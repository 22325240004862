import React, { useState, useEffect } from "react";
import { realTimeDb } from "./register/firebase";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ref, get, push } from "firebase/database";
import "./BlogDetails.css";

function BlogDetails() {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [showComments, setShowComments] = useState(false); // State variable to track if comments are visible

  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        const postRef = ref(realTimeDb, `blog_posts/${id}`);
        const snapshot = await get(postRef);
        if (snapshot.exists()) {
          setPost(snapshot.val());
        } else {
          console.log("No post found with ID:", id);
        }
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    fetchPostDetails();
  }, [id]);

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const submitNewComment = () => {
    if (newComment.trim() !== "") {
      const commentId = uuidv4();
      const commentRef = ref(realTimeDb, `blog_posts/${id}/comments`);
      push(commentRef, newComment)
        .then(() => {
          console.log("Comment added successfully!");
          setNewComment("");
        })
        .catch((error) => {
          console.error("Error adding comment:", error);
        });
    } else {
      console.log("Please enter a valid comment");
    }
  };

  return (
    <div className="blog-details-container">
      {post ? (
        <div className="post-details">
          <h2 className="post-title">{post.title}</h2>
          <p className="post-description">{post.description}</p>
          <p className="comment-count" onClick={toggleComments}>
            Comments ({Object.keys(post.comments).length})
          </p>
          {showComments && (
            <ul className="comment-list">
              {Object.keys(post.comments).map((key) => (
                <li key={key} className="comment-item">
                  {post.comments[key]}
                </li>
              ))}
              <input
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Enter your comment"
                className="comment-input"
              />
              <button onClick={submitNewComment} className="submit-comment-btn">
                Submit Comment
              </button>
            </ul>
          )}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default BlogDetails;
