import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./register/firebase";
import DashBoardMenu from "./DashBoardMenu";
import "./StrategyCard.css";

const RunningStrategies = () => {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Cleanup auth listener on unmount\
  }, []);

  return (
    <div className="dashboard-home-container">
      <DashBoardMenu />
      {/* Add any additional components or JSX here */}
    </div>
  );
};

export default RunningStrategies;
