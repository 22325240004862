// src/OrderChart.js
import React, { useState, useEffect, useRef } from "react";
import CandlestickChart from "./CandlestickChart";
import rupee from "../images/rupee.png";
import sell from "../images/nifty_up.png";

import buy from "../images/nifty_down.png";
import time from "../images/time.png";
import "./OrderDetails.css";
import axios from "axios";
import { createChart } from "lightweight-charts";
import StrategyService from "../services/StrategyService";

const OrderChart = ({ order }) => {
  const [candleData, setCandleData] = useState([]);
  const [error, setError] = useState(null);
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const candlestickSeriesRef = useRef(null);
  const stockName = order.stock.replace(/\s+/g, "_");

  const [executedOrders, setExcutedOrder] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://replayserver.algoturtle.com/get_historical_data_for_stock?stock_string=${stockName}`
        );
        const sortedData = response.data.sort(
          (a, b) => a.timestamp - b.timestamp
        );
        setCandleData(sortedData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchExOrders();
    fetchData();
  }, [order.stock]);

  const fetchExOrders = () => {
    StrategyService.getExecutedOrder(
      order.id,
      (response) => {
        console.log(response);
        setExcutedOrder(response);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const [isMobileView, setIsMobileView] = useState(false);

  // Function to check window width and update state
  const checkScreenSize = () => {
    setIsMobileView(window.innerWidth <= 900);
  };

  // Effect to check screen size on mount and resize
  useEffect(() => {
    checkScreenSize(); // Check on mount
    window.addEventListener("resize", checkScreenSize); // Listen for resize events
    return () => {
      window.removeEventListener("resize", checkScreenSize); // Clean up on unmount
    };
  }, []);

  useEffect(() => {
    if (candleData.length === 0 || !chartContainerRef.current) return;

    if (!chartRef.current) {
      chartRef.current = createChart(chartContainerRef.current, {
        width: !isMobileView ? 610 : 290,
        height: 280,
      });
      candlestickSeriesRef.current = chartRef.current.addCandlestickSeries();
    }

    const formattedData = candleData.map((candle) => ({
      time: candle.timestamp / 1000, // Convert to seconds
      open: candle.open,
      high: candle.high,
      low: candle.low,
      close: candle.close,
    }));

    candlestickSeriesRef.current.setData(formattedData);

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
        candlestickSeriesRef.current = null;
      }
    };
  }, [candleData]);

  const candlestickOptions = {
    layout: {
      backgroundColor: "#ffffff",
      textColor: "#000000",
    },
    grid: {
      vertLines: {
        color: "#e0e0e0",
      },
      horzLines: {
        color: "#e0e0e0",
      },
    },
  };

  return (
    <div className="graph-details-container">
      <div>
        <div ref={chartContainerRef} className="graph-container"></div>
      </div>
      <div className="executed-order-details-container">
        <ul>
          {executedOrders.map((order) => (
            <div key={order.id} className="executed-order-details">
              <img
                src={order.orderType == "SELL" ? sell : buy}
                alt="Status Icon"
                className="executed-order-details-status-icon"
              />
              <div className="executed-order-details-text-name">
                {order.orderType}
              </div>
              <div className="time-section">
                <img
                  src={rupee}
                  alt="Time Icon"
                  className="executed-order-details-time-icon"
                />
                <div className="executed-order-details-text-price">20*100</div>
              </div>
              <div className="time-section">
                <img
                  src={time}
                  alt="Time Icon"
                  className="executed-order-details-time-icon"
                />
                <div className="executed-order-details-text-time">
                  {order.placedAt}
                </div>
              </div>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OrderChart;
