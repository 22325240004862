import React, { useState, useRef, useEffect } from "react";
import "./PnlAdapter.css"; // Importing the CSS file for styles
import redDot from "./red_dot.png";
import greenDot from "./green_dot.png";
import warningDot from "./warning.png";
import recDot from "./rec_dot.png";
import OrderDetails from "./OrderDetails";
import FullMovableDialog from "./FullMovableDialog";

const PnlAdapter = ({ dataList }) => {
  const [openOrderDetailsb, setOpenOrderDetails] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const dialogRef = useRef();

  const openOrderDetails = (data) => {
    setSelectedData(data);
    setOpenOrderDetails(true);
  };

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      setOpenOrderDetails(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderItems = () => {
    return dataList.map((data, index) => (
      <div key={index}>
        <div className="item-container" onClick={() => openOrderDetails(data)}>
          <div className="progress-bar-container-pnl">
            <div
              className="progress-bar-pnl"
              style={{
                width: `${calculateProgressWidth(data)}%`,
                backgroundColor: getBackgroundColor(data.pnl),
              }}
            />
            <div className="details-container">
              <span className="text-strategy">{data.Strategy_name}</span>
              {renderStatusIndicator(data)}
              <span className="text-stock">{data.Stock_name}</span>
              <span className="text broker">
                {data.broker?.toLowerCase() === "mock"
                  ? "T"
                  : data.broker?.toUpperCase().charAt(0)}
              </span>
              <span
                className="text-pnl"
                style={{ color: data.pnl < 0 ? "darkred" : "darkgreen" }}
              >
                {data.pnl.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const createOrder = (data) => {
    return {
      stock: data.Stock_name,
      strategy_metadata: {
        initial_capital: data.initial_capital,
        strategy_name: data.Strategy_name,
        start_time: data.timestamp,
        additional_metadata: {
          profit_range_max: data.max_profit,
          max_lots: data.max_lots,
          profit_range_min: data.min_profit,
          max_loss: data.max_loss,
        },
      },
      strategy_status: data.strategy_status,
      user_id: data.user_id,
      id: data.id,
      broker_type: data.broker,
      current_strategy_status: data.current_strategy_status,
    };
  };

  const calculateProgressWidth = (data) => {
    if (data.pnl > 0) {
      return (data.pnl / data.max_profit) * 100;
    } else {
      return (-data.pnl / data.max_loss) * 100;
    }
  };

  const renderStatusIndicator = (data) => {
    if (data.strategy_status.toLowerCase() === "running") {
      return (
        <img
          className="status-image"
          src={data.buy_or_sell?.toLowerCase() === "buy" ? greenDot : redDot}
          alt="status"
        />
      );
    } else if (data.strategy_status.toLowerCase() === "stopped") {
      return <img className="status-image" src={recDot} alt="status" />;
    } else if (data.strategy_status.toLowerCase() === "failure") {
      return <img className="failure-image" src={warningDot} alt="warning" />;
    }
    return null;
  };

  const getBackgroundColor = (pnl) => {
    if (pnl === 0) {
      return "gray";
    } else if (pnl > 0) {
      return "#90ee90";
    } else {
      return "#ffcccb";
    }
  };

  return (
    <div className="pnl-adapter">
      {renderItems()}
      {openOrderDetailsb && (
        <FullMovableDialog
          ref={dialogRef}
          onClose={() => setOpenOrderDetails(false)}
        >
          <OrderDetails order={createOrder(selectedData)} />
        </FullMovableDialog>
      )}
    </div>
  );
};

export default PnlAdapter;
