// src/register/firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import {
  getAuth,
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBGnxZQCJYM8Yjy4j9eWrBl-HnkTbDMXso",
  authDomain: "algoturtle-alpha.firebaseapp.com",
  databaseURL: "https://algoturtle-alpha-default-rtdb.firebaseio.com",
  projectId: "algoturtle-alpha",
  storageBucket: "algoturtle-alpha.appspot.com",
  messagingSenderId: "1044608140682",
  appId: "1:1044608140682:web:a6671c951ef850ed04b473",
  measurementId: "G-G04ZYKYRZN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const realTimeDb = getDatabase(app);
const auth = getAuth(app);

// Export the necessary functions
export {
  db,
  realTimeDb,
  auth,
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
};
