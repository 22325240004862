import React, { useState } from "react";
import "./FAQs.css";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import expand from "../images/expand.png";
import collapse from "../images/collapse.png";
function FAQs() {
  const FAQs = [
    {
      question: "What makes Algoturtle unique?",
      answer:
        "	India's first Ai based algo trading platform. Develop your own strategies with us or use one of our own. Strategies available for every market condition, trending or sideways.Expiry Special (CAP) (Nifty) Amazing strategies. Unique UI",
    },
    {
      question: "What is Algoturtle?",
      answer:
        "Algoturtle is India's first AI-based algorithmic trading platform.",
    },
    {
      question: "How can I develop my own trading strategies?",
      answer:
        "Algoturtle empowers users to craft their own trading strategies using advanced tools and resources provided on the platform. Empowers users to craft their own trading strategies using advanced tools and resources provided on the platform. Empowers users to craft their own trading strategies using advanced tools and resources provided on the platform.",
    },
    {
      question: "Why choose Algoturtle?",
      answer:
        "	Trade like never before. Trade without emotions. Stress-free, systematic and disciplined.",
    },
    {
      question: "Can I customize/ develop strategies ?",
      answer:
        "		Well of course YOU CAN.We specialize in Custom strategies.Custom strategies are one of our higher selling product.",
    },
    {
      question: "How do I get started?",
      answer: "	Register here our team will contact you. REGISTER NOW",
    },

    // Add more FAQs as needed
  ];
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const toggleExpand = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const getRandomLightColor = () => {
    const letters = "ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  return (
    <div className="faq--container">
      <h1 className="text-center my-5">Frequently Asked Questions</h1>
      <div className="card--container--faqs">
        {FAQs.map((faq, index) => (
          <div key={index} className="faq-card">
            <Card>
              <Card.Header
                onClick={() => toggleExpand(index)}
                className="card-header"
              >
                <span>{faq.question}</span>
                <span className="expand-icon">
                  {expandedIndex === index ? (
                    <img src={collapse} alt="Collapse" />
                  ) : (
                    <img src={expand} alt="Expand" />
                  )}
                </span>
              </Card.Header>
              {expandedIndex === index && (
                <Card.Body>
                  <Card.Text style={{ backgroundColor: getRandomLightColor() }}>
                    {faq.answer}
                  </Card.Text>
                </Card.Body>
              )}
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQs;
