import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { db, auth } from "./register/firebase";
import StrategyService from "../services/StrategyService";
import UserDetails from "./UserDetails";
import OrderFragment from "./OrderFragment";
import BrokersFragment from "./BrokersFragment";
import ChartsFragment from "./ChartsFragment";

import homeIcon from "../images/home.png";
import ordersIcon from "../images/orders.png";
import brokerIcon from "../images/broker.png";
import chartIcon from "../images/charts.png";
import userIcon from "../images/user_pic.png";

import "./DashBoardMenu.css";
import expandMenu from "../images/arrow_pointng_to_left.png";
import minizeMenu from "../images/arrow_pointng_to_right.png";

import "./DashboardNavbar.css";
import DashboardHome from "./DashboardHome";
import Analysis from "./Analysis";

import notificatioImg from "../images/notification.png";
import logo from "../images/logo.jpg";
import userPic from "../images/user_pic.png";

function DashBoardMenu() {
  const [isMobileView, setIsMobileView] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);

  const checkScreenSize = () => {
    setIsMobileView(window.innerWidth <= 900);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [strategies, setStrategies] = useState([]);
  const [runningStrategies, setRunningStrategies] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [listOfStrategies, setListOfStrategies] = useState([]);
  const [proStrategies, setProStrategies] = useState([]);
  const [privateStrategies, setPrivateStrategies] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [allRunningFromDb, setAllRunningFromDb] = useState([]);

  const fetchStrategies = async () => {
    const user = auth.currentUser;
    if (!user || !user.uid) {
      navigate("/login");
      return;
    }

    const uuid = user.uid;
    try {
      if (window.location.pathname.includes("/dashboard")) {
        const response = await axios.get(
          `https://algoturtle.com/get_strategy_status_for_user?user_id=${uuid}`,
          { timeout: 30000 }
        );
        if (response.status === 200) {
          const strategyArray = Object.keys(response.data).map((key) => ({
            id: key,
            ...response.data[key],
          }));
          const runningStrategiesArray = strategyArray.filter(
            (strategy) => strategy.strategy_status.toLowerCase() === "running"
          );
          setRunningStrategies(runningStrategiesArray);
          setStrategies(strategyArray);
          setDataList(strategyArray);
        } else {
          console.error(
            `Failed to fetch strategies. Status: ${response.status}`
          );
        }
      }
    } catch (error) {
      console.error("Error fetching strategies:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOnlyRunningFromDb = async () => {
    StrategyService.fetchAllRunning(
      (list) => setAllRunningFromDb(list),
      (error) => console.log(error)
    );
  };

  const [showTitles, setShowTitles] = useState(false);

  const handleExpandMenuClick = () => {
    setShowTitles(!showTitles);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate(`/dashboard?uid=${user.uid}`);
        StrategyService.userDetails(
          user.uid,
          (data) => setUserDetails(data),
          (error) => console.log(error)
        );
        fetchStrategies();
        fetchOnlyRunningFromDb();
        const intervalId = setInterval(fetchStrategies, 3000);
        return () => clearInterval(intervalId);
      } else {
        navigate("/login");
      }
    });

    const fetchListOfStrategies = async () => {
      try {
        const strategies = await StrategyService.fetchListOfStrategiesFromDB(
          "list_of_strategies"
        );
        setListOfStrategies(strategies);
        const proStrategies = await StrategyService.fetchListOfStrategiesFromDB(
          "pro_strategies"
        );
        setProStrategies(proStrategies);
        const privateStrategies =
          await StrategyService.fetchPrivateStrategiesFromDB();
        setPrivateStrategies(privateStrategies);
      } catch (error) {
        console.error("Error fetching strategies:", error);
      }
    };

    fetchListOfStrategies();
    return () => clearInterval(30000);
  }, [navigate]);

  const [activeInnerTab, setActiveInnerTab] = useState("home");

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.log("Notification permission denied.");
        }
      });
    }

    const user = auth.currentUser;
    if (!user || !user.uid) return;

    const eventSource = new EventSource(
      `https://notify.algoturtle.com/${user.uid}/sse`
    );

    eventSource.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        newNotification,
      ]);

      // Display browser notification
      if (Notification.permission === "granted") {
        new Notification(newNotification.title, {
          body: newNotification.message,
          icon: logo,
        });
      }
    };

    eventSource.onerror = (error) => {
      console.error("Error with SSE connection:", error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <div className="dashboard-menu-nav">
      <div className="extra-tab-container">
        <img
          className="expand-menu-img"
          src={showTitles ? expandMenu : minizeMenu}
          onClick={handleExpandMenuClick}
        />
        <div
          className={`extra-tab-button ${
            activeInnerTab === "user" ? "active" : ""
          }`}
          onClick={() => setActiveInnerTab("user")}
        >
          <div className="selected"></div>
          <img
            src={
              userDetails && userDetails.imageUri
                ? userDetails.imageUri
                : userIcon
            }
            alt="user"
            className="extra-tab-icon"
          />
          {showTitles && <p className="extra-tab-title">Account</p>}
        </div>
        <div
          className={`extra-tab-button ${
            activeInnerTab === "home" ? "active" : ""
          }`}
          onClick={() => setActiveInnerTab("home")}
        >
          <div className="selected"></div>
          <img src={homeIcon} alt="Home" className="extra-tab-icon" />
          {showTitles && <p className="extra-tab-title">Home</p>}
        </div>
        <div
          className={`extra-tab-button ${
            activeInnerTab === "orders" ? "active" : ""
          }`}
          onClick={() => setActiveInnerTab("orders")}
        >
          <div className="selected"></div>
          <img src={ordersIcon} alt="orders" className="extra-tab-icon" />
          {showTitles && <p className="extra-tab-title">Orders</p>}
        </div>
        <div
          className={`extra-tab-button ${
            activeInnerTab === "brokers" ? "active" : ""
          }`}
          onClick={() => setActiveInnerTab("brokers")}
        >
          <div className="selected"></div>
          <img src={brokerIcon} alt="brokers" className="extra-tab-icon" />
          {showTitles && <p className="extra-tab-title">Brokers</p>}
        </div>
        <div
          className={`extra-tab-button ${
            activeInnerTab === "chart" ? "active" : ""
          }`}
          onClick={() => setActiveInnerTab("chart")}
        >
          <div className="selected"></div>
          <img src={chartIcon} alt="chart" className="extra-tab-icon" />
          {showTitles && <p className="extra-tab-title">Charts</p>}
        </div>
      </div>
      <div className="dashboard-nav-bar-main-con">
        <div className="dashboard-nav-bar-con">
          <a className="dashboard-nav-title" href="/">
            <span style={{ color: "#2D3192" }}>algo</span>
            <span style={{ color: "#008000" }}>Turtle</span>
          </a>
          <div className="dashboard-nav-items">
            <Badge badgeContent={notifications.length} color="primary">
              <NotificationsIcon
                className="image"
                onClick={handleNotificationClick}
              />
            </Badge>
            <img
              className="image"
              src={userDetails?.imageUri || userPic}
              alt="User"
              onError={(e) => {
                e.target.src = userPic; // Fallback to userPic if imageUri fails to load
                console.log(userDetails?.imageUri);
              }}
              onClick={() => setActiveInnerTab("user")}
            />
            <p className="user-name">
              {userDetails != null ? userDetails.name : "name"}
            </p>
          </div>
        </div>
        <div>
          <DashboardHome
            className="dashboard-navbar-pnl-home"
            activeInnerTab={activeInnerTab}
            setActiveInnerTab={setActiveInnerTab}
          />
        </div>
        {showNotifications && (
          <div className="notifications-window">
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <div key={index} className="notification-card">
                  <p className="notification-title">{notification.title}</p>
                  <p className="notification-message">{notification.message}</p>
                  <p className="notification-time">
                    {new Date(notification.time * 1000).toLocaleString()}
                  </p>
                  {/* <p className="notification-priority">
                    Priority: {notification.priority}
                  </p> */}
                </div>
              ))
            ) : (
              <p>No notifications</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DashBoardMenu;
