import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

import StrategyCard from "./StrategyCard";
import StrategyItem from "./StrategyItem";
import PnlAdapter from "./PnlAdapter";
import UserDetails from "./UserDetails";
import OrderFragment from "./OrderFragment";
import BrokersFragment from "./BrokersFragment";
import ChartsFragment from "./ChartsFragment";
import OrderItem from "./OrderItem";
import Analysis from "./Analysis";
import StrategyService from "../services/StrategyService";
import { auth } from "./register/firebase";
import redDot from "../images/red_dot.png";
import greenDot from "../images/green_dot.png";
import recdot from "../images/rec_dot.png";
import failure from "../images/failure.png";
import filter from "../images/filter.png";

import "./StrategyCard.css";
import "./Analysis.css";

function DashboardHome({ activeInnerTab, setActiveInnerTab }) {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 900);
  const [strategies, setStrategies] = useState([]);
  const [runningStrategies, setRunningStrategies] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [listOfStrategies, setListOfStrategies] = useState([]);
  const [proStrategies, setProStrategies] = useState([]);
  const [privateStrategies, setPrivateStrategies] = useState([]);
  const [activeTab, setActiveTab] = useState("running");
  const [userDetails, setUserDetails] = useState(null);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(true);
  const [brokers, setBrokers] = useState([]);

  const [selectedBroker, setSelectedBroker] = useState("All");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const navigate = useNavigate();

  // strategy status change code

  const [items, setItems] = useState([
    { id: 1, imgSrc: filter, text: "All" },
    { id: 2, imgSrc: redDot, text: "Sell" },
    { id: 3, imgSrc: greenDot, text: "Buy" },
    { id: 4, imgSrc: recdot, text: "Stopped" },
    { id: 4, imgSrc: failure, text: "Failure" },
  ]);
  const handlechageFilter = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const checkScreenSize = () => {
    setIsMobileView(window.innerWidth <= 900);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (!user || !user.phoneNumber) {
      navigate(`/verify-phonenumber/${user ? user.uid : ""}`);
    }
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, [navigate]);

  const [selected, setSelected] = useState("pro");

  const [totalPnlSum, setTotalPnlSum] = useState(0);
  const [runningPnlSum, setRunningPnlSum] = useState(0);

  const getPnlSum = (orders) => {
    let sum = 0;
    for (const order of orders) {
      sum += order.pnl;
    }
    return sum;
  };

  const fetchStrategies = async () => {
    const user = auth.currentUser;
    if (!user || !user.uid) {
      navigate("/login");
      return;
    }

    try {
      if (window.location.pathname.includes("/dashboard")) {
        const url = `https://algoturtle.com/get_strategy_status_for_user?user_id=${user.uid}`;
        console.log(url);
        const response = await axios.get(
          `https://algoturtle.com/get_strategy_status_for_user?user_id=${user.uid}`,
          { timeout: 30000 }
        );

        if (response.status === 200) {
          const strategyArray = Object.keys(response.data).map((key) => ({
            id: key,
            ...response.data[key],
          }));

          setRunningStrategies(
            strategyArray.filter(
              (strategy) =>
                strategy.strategy_status.toLowerCase() === "running" &&
                (selectedBroker === "All" || strategy.broker === selectedBroker)
            )
          );
          console.log(response);
          const uniqueBrokers = extractUniqueBrokers(strategyArray);
          setBrokers(uniqueBrokers);
          setStrategies(strategyArray);
          setTotalPnlSum(getPnlSum(strategyArray));

          // strategyArray.sort()
          setRunningPnlSum(
            getPnlSum(
              strategyArray.filter(
                (strategy) =>
                  strategy.strategy_status.toLowerCase() === "running" &&
                  (selectedBroker === "All" ||
                    strategy.broker === selectedBroker)
              )
            )
          );
          setDataList(strategyArray);
        } else {
          console.error(
            `Failed to fetch strategies. Status: ${response.status}`
          );
        }
      }
    } catch (error) {
      console.error("Error fetching strategies:", error);
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  const fetchListOfStrategies = async () => {
    try {
      const listStrategies = await StrategyService.fetchListOfStrategiesFromDB(
        "list_of_strategies"
      );
      const proStrat = await StrategyService.fetchListOfStrategiesFromDB(
        "pro_strategies"
      );
      const privateStrat = await StrategyService.fetchPrivateStrategiesFromDB();

      setListOfStrategies(listStrategies);
      setProStrategies(proStrat);
      setPrivateStrategies(privateStrat);
    } catch (error) {
      console.error("Error fetching strategies:", error);
    }
  };

  const [allRunningFromDb, setAllRunningFromDB] = useState([]);

  const fetchOnlyRunningFromDb = async () => {
    try {
      await StrategyService.fetchAllRunning(
        (list) => {
          console.log("Fetched list:", list);
          setAllRunningFromDB(list);
        },
        (error) => {
          console.error("Error fetching running strategies:", error);
        }
      );
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  useEffect(() => {
    fetchOnlyRunningFromDb();
  }, []);

  useEffect(() => {
    console.log("allRunningFromDb updated:", allRunningFromDb);
  }, [allRunningFromDb]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate(`/dashboard?uid=${user.uid}`);
        setIsUserAuthenticated(true);
        StrategyService.userDetails(user.uid, setUserDetails, console.log);
        fetchStrategies();
        const intervalId = setInterval(fetchStrategies, 30000); // Fetch strategies every 30 seconds
        return () => clearInterval(intervalId);
      } else {
        setIsUserAuthenticated(false);
        if (window.location.pathname === "/dashboard") {
          navigate("/login");
        }
      }
    });

    fetchListOfStrategies();

    return () => unsubscribe();
  }, [navigate]);

  const [isActive, setIsActive] = useState(true);

  const extractUniqueBrokers = (data) => {
    const brokers = data.map((item) => item.broker);
    brokers.push("All");
    return [...new Set(brokers)];
  };
  const handleChangeFilter = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleBrokerSelect = (broker) => {
    setSelectedBroker(broker);
    const updatedItems = items.map((item) =>
      item.id === 1 ? { ...item, text: broker } : item
    );
    setItems(updatedItems);
  };

  return (
    <div>
      <div className="extra-tab-content">
        {activeInnerTab === "user" && (
          <UserDetails
            userDetails={userDetails}
            onIconClick={() => setActiveInnerTab("brokers")}
          />
        )}

        {activeInnerTab === "home" && (
          <div className="dashboard-analysis-con">
            <div className="extra-tab-content">
              <div className="tab-container">
                <div className="button-pnl-con">
                  <button
                    className={`tab-button ${
                      activeTab === "running" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("running")}
                  >
                    Running Strategies ({runningStrategies.length})
                    <p style={{ color: runningPnlSum >= 0 ? "green" : "red" }}>
                      Rs. {runningPnlSum}
                    </p>
                  </button>
                  <div className="innerContainer">
                    <PnlAdapter dataList={runningStrategies} />
                  </div>
                </div>
                <div className="button-pnl-con">
                  <button
                    className={`tab-button ${
                      activeTab === "total" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("total")}
                  >
                    Total Strategies ({strategies.length})
                    <p style={{ color: totalPnlSum >= 0 ? "green" : "red" }}>
                      Rs. {totalPnlSum}
                    </p>
                  </button>
                  <div className="innerContainer">
                    <PnlAdapter dataList={dataList} />
                  </div>
                </div>
              </div>
              {activeTab === "running" && (
                <div className="single-pnl-container">
                  <PnlAdapter dataList={runningStrategies} />
                </div>
              )}
              {activeTab === "total" && (
                <div className="single-pnl-container">
                  <PnlAdapter dataList={dataList} />
                </div>
              )}
              <div className="strategy-status-container">
                {dropdownVisible && (
                  <select onChange={(e) => handleBrokerSelect(e.target.value)}>
                    <option value="">Select Broker</option>
                    {brokers.map((broker, index) => (
                      <option key={index} value={broker}>
                        {broker}
                      </option>
                    ))}
                  </select>
                )}
                {items.map((item, index) => (
                  <div key={item.id} onClick={() => handleChangeFilter(index)}>
                    <img src={item.imgSrc} alt={item.text} />
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>

              <h1 className="section-title">
                Running Strategies ({allRunningFromDb.length})
              </h1>
              <div
                className="orders-list-container"
                style={{ maxHeight: "310px", overflow: "auto" }}
              >
                {allRunningFromDb.length === 0 ? (
                  <p>No orders available</p>
                ) : (
                  allRunningFromDb.map((order) => (
                    <OrderItem key={order.id} order={order} />
                  ))
                )}
              </div>

              <div>
                <div>
                  <button
                    className={`section-title-btn-strategy ${
                      selected === "private" ? "active" : ""
                    }`}
                    onClick={() => setSelected("private")}
                  >
                    Private Strategies ({privateStrategies.length})
                  </button>

                  <button
                    className={`section-title-btn-strategy ${
                      selected === "pro" ? "active" : ""
                    }`}
                    onClick={() => setSelected("pro")}
                  >
                    Pro Strategies ({proStrategies.length})
                  </button>

                  <button
                    className={`section-title-btn-strategy ${
                      selected === "public" ? "active" : ""
                    }`}
                    onClick={() => setSelected("public")}
                  >
                    Public Strategies ({listOfStrategies.length})
                  </button>
                </div>

                <div className="strategy-list-con">
                  {/* private strategies list */}
                  {selected && selected === "private" && (
                    <div>
                      <div className="strategy-list">
                        {privateStrategies.map((strategy) => (
                          <StrategyItem
                            key={strategy.id}
                            strategy={strategy}
                            strategyType="private"
                          />
                        ))}
                      </div>

                      {privateStrategies.length === 0 && (
                        <div className="parent-container">
                          <h4 className="create-strategy">
                            No Data
                            <a href="/register">Build One Strategy</a>
                          </h4>
                        </div>
                      )}
                    </div>
                  )}
                  {/* pro strategies list  */}

                  {selected && selected === "pro" && (
                    <div>
                      <div className="strategy-list">
                        {proStrategies.map((strategy) => (
                          <StrategyItem
                            key={strategy.id}
                            strategy={strategy}
                            strategyType="pro"
                          />
                        ))}
                      </div>
                      <div>
                        {proStrategies.length === 0 && (
                          <div className="parent-container">
                            <h4 className="create-strategy">No Data avai</h4>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {/* public strategies list */}
                  {selected && selected === "public" && (
                    <div>
                      <div className="strategy-list">
                        {listOfStrategies.map((strategy) => (
                          <StrategyItem
                            key={strategy.id}
                            strategy={strategy}
                            strategyType="public"
                          />
                        ))}
                      </div>
                      <div>
                        {listOfStrategies.length === 0 && (
                          <div className="parent-container">
                            <h4 className="create-strategy">
                              No Data available
                            </h4>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="right-side-charts">
              <ChartsFragment className="analysis-navbar" />
            </div>
          </div>
        )}
        {activeInnerTab === "orders" && <OrderFragment />}
        {activeInnerTab === "brokers" && <BrokersFragment />}
        {activeInnerTab === "chart" && <ChartsFragment />}
      </div>
    </div>
  );
}

export default DashboardHome;
