// src/StrategyItem.js
import React, { useState } from "react";
import "./StrategyItem.css"; // Import your CSS file for styling

import strategyIcon from "./stretegy_icon.png"; // Adjust path to your image file
import { useNavigate } from "react-router-dom";
import { useScroll } from "react-spring";
import RunStrategyActivity from "./RunStrategyActivity";
import MovableDialog from "./MovableDialog";
import logogif from "../images/logo-progress.gif";
import UserService from "../services/UserService";
import { auth } from "./register/firebase";

const StrategyItem = ({ strategy, strategyType }) => {
  const navigate = useNavigate();
  const [mstrategy, setProp] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  // const moveToRunstrategy = () => {
  //   if (strategy) {
  //     const uid = auth.currentUser ? auth.currentUser.uid : null;
  //     setLoading(true);
  //     setOpenDialog(true);
  //     if (strategyType === "pro") {
  //       const canRunstrategy = UserService.doesUserCanRunProStrategy(
  //         uid,
  //         () => {
  //           const categoryModel = {
  //             strategy_collection: strategy.strategyName,
  //             maxLossPercentage: strategy.maxLossPercentage,
  //             maxLots: strategy.maxLots,
  //             maxProfitPercentage: strategy.maxProfitPercentage,
  //             minProfitPercentage: strategy.minProfitPercentage,
  //             sop: strategy.sop,
  //             strategyName: strategy.strategyName,
  //             displayName: strategy.displayName,
  //             trending: strategy.trending,
  //             date: strategy.formattedDate,
  //             initialCapital: strategy.initialCapital,
  //             type: strategy.collection,
  //           };
  //           setLoading(false);
  //           setOpenDialog(false);
  //           setProp(categoryModel);
  //         },
  //         () => {
  //           setLoading(false);
  //           setOpenDialog(false);
  //           navigate("/pro");
  //         }
  //       );
  //     }

  //     // navigate(`/runstrategy/${strategy.strategyName}`, {
  //     //   state: { strategies: [categoryModel] },
  //     // });
  //   }
  // };

  const moveToRunstrategy = () => {
    if (strategy) {
      const uid = auth.currentUser ? auth.currentUser.uid : null;
      setLoading(true);
      setOpenDialog(true);

      if (strategyType === "pro") {
        UserService.doesUserCanRunProStrategy(
          uid,
          () => {
            const categoryModel = {
              strategy_collection: strategy.strategyName,
              maxLossPercentage: strategy.maxLossPercentage,
              maxLots: strategy.maxLots,
              maxProfitPercentage: strategy.maxProfitPercentage,
              minProfitPercentage: strategy.minProfitPercentage,
              sop: strategy.sop,
              strategyName: strategy.strategyName,
              displayName: strategy.displayName,
              trending: strategy.trending,
              date: strategy.formattedDate,
              initialCapital: strategy.initialCapital,
              type: strategy.collection,
            };
            setLoading(false);
            setOpenDialog(false);
            setProp(categoryModel);
            // navigate(`/runstrategy/${strategy.strategyName}`, {
            //   state: { strategies: [categoryModel] },
            // });
          },
          (message) => {
            setLoading(false);
            setOpenDialog(false);
            alert(message);

            setProp(null);
            if (message !== "Pro user can run max 10 strategies") {
              // navigate(`/upgrade-to-pro?uid=${uid}`);
              navigate(`/upgrade-to-pro?uid=${uid}`);
            }
          }
        );
      } else if (strategyType === "public" || strategyType === "private") {
        UserService.doesUserCanRunPublicStrategy(
          uid,
          () => {
            const categoryModel = {
              strategy_collection: strategy.strategyName,
              maxLossPercentage: strategy.maxLossPercentage,
              maxLots: strategy.maxLots,
              maxProfitPercentage: strategy.maxProfitPercentage,
              minProfitPercentage: strategy.minProfitPercentage,
              sop: strategy.sop,
              strategyName: strategy.strategyName,
              displayName: strategy.displayName,
              trending: strategy.trending,
              date: strategy.formattedDate,
              initialCapital: strategy.initialCapital,
              type: strategy.collection,
            };
            setLoading(false);
            setOpenDialog(false);
            setProp(categoryModel);
            // navigate(`/runstrategy/${strategy.strategyName}`, {
            //   state: { strategies: [categoryModel] },
            // });
          },
          (message) => {
            setProp(null);
            setLoading(false);
            setOpenDialog(false);
            if (message === "Basic User can run max 4 strategies") {
              navigate(`/upgrade-to-pro?uid=${uid}`);
            }
            console.log(message);
            alert(message);
          }
        );
      }
      //else {
      //    setLoading(false);
      //    setOpenDialog(false);
      //    navigate(`/runstrategy/${strategy.strategyName}`, {
      //      state: { strategies: [categoryModel] },
      //    });
      //  }
    }
  };

  return (
    <div>
      <div className="strategy-item" onClick={moveToRunstrategy}>
        <div className="strategy-name-icon">
          <img
            src={strategyIcon} // Adjust path to your image file
            alt="chart"
            className="imgchart"
          />
          <h3 className="txtname">{strategy.strategyName}</h3>
        </div>
        <p className="txtdetails">{strategy.displayName}</p>
        <div className="linbottom">
          <div className="relative-layout">
            <div className="txtpercent_ll round-back">
              <img
                src={strategyIcon} // Adjust path to your image file
                alt="trend"
                className="icon"
              />
              <span className="txtpercent">
                {strategy.maxProfitPercentage} %
              </span>
            </div>
          </div>
          <div className="relative-layout">
            <div className="popular_ll round-back">
              <img
                src={strategyIcon} // Adjust path to your image file
                alt="trend"
                className="icon"
              />
              <span className="txtpercent">{strategy.trending}</span>
            </div>
          </div>
        </div>
        <div className="progress-bar"></div>
      </div>
      {mstrategy && (
        <MovableDialog onClose={() => setProp(null)}>
          <div>
            <h2 className="title">{strategy.strategyName}</h2>
            <RunStrategyActivity
              mstrategy={mstrategy}
              strategyType={strategyType}
            />
          </div>
        </MovableDialog>
      )}

      {openDialog && (
        <div className="dialog">
          <div className="dialog-content">
            <img src={logogif} alt="Loading" className="logo-gif" />
            <p>{loading ? "Processing..." : "Processing..."}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StrategyItem;
