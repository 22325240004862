import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import "./SuccessPage.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const SuccessPage = () => {
  return (
    <div>
      <Navbar />
      <div className="success-container">
        <FaCheckCircle className="success-icon" />
        <h1 className="success-heading">Payment Successful!</h1>
        <p className="success-message">
          Your payment has been successfully processed.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default SuccessPage;
