import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from "firebase/auth";
import { RecaptchaVerifier } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core"; // Import material-ui components for progress dialogs
import "./Login.css";

import logogif from "../images/logo-progress.gif";
const Login = () => {
  const navigate = useNavigate();
  const [loginMethod, setLoginMethod] = useState("email");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState();
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State to control whether the dialog is open
  const auth = getAuth();

  const handleLoginMethodChange = (method) => {
    setLoginMethod(method);
    setShowOtpInput(false);
  };

  const handleEmailPasswordLogin = async (e) => {
    try {
      setLoading(true); // Start loading indicator
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      // console.log("User signed in with email/password:", user.uid);

      if (auth.currentUser && auth.currentUser.phoneNumber) {
        navigate(`/pricing?uid=${user.uid}`);
      } else {
        navigate(`/verify-phonenumber/${user.uid}`);
      }
    } catch (error) {
      console.error("Error signing in with email/password:", error);
      alert(
        "Error signing in with email/password. Please try again." +
          error.message
      );
    } finally {
      setLoading(false); // Stop loading indicator regardless of success or failure
    }
  };

  const handleLogin = () => {
    if (loginMethod === "email") {
      handleEmailPasswordLogin();
    } else {
      onOTPVerify();
    }
  };

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      setLoading(false); // Start loading indicator
      setOpenDialog(false);
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "normal",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        }
      );
    }
  };

  const onSignup = () => {
    setLoading(true); // Start loading indicator
    setOpenDialog(true); // Open the progress dialog
    if (!window.recaptchaVerifier) {
      onCaptchVerify();
    } else {
      setLoading(false); // Start loading indicator
      setOpenDialog(false);
    }
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+91" + phoneNumber;
    try {
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false); // Stop loading indicator
          setShowOtpInput(true);
          setOpenDialog(false); // Close the progress dialog
          alert("OTP sent successfully!");
        })
        .catch((error) => {
          console.error("Error signing in with phone number:", error);
          setLoading(false); // Stop loading indicator
          setOpenDialog(false); // Close the progress dialog
          alert("Error sending OTP. Please try again.");
        });
    } catch (error) {
      console.error("Error signing in with phone number:", error);
      setLoading(false); // Stop loading indicator
      setOpenDialog(false); // Close the progress dialog
      alert("An error occurred. Please try again.");
    }
  };

  const onOTPVerify = () => {
    setLoading(true); // Start loading indicator
    setOpenDialog(true); // Open the progress dialog
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false); // Stop loading indicator
        setOpenDialog(false); // Close the progress dialog
        navigate(`/pricing?uid=${user.uid}`);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false); // Stop loading indicator
        setOpenDialog(false); // Close the progress dialog
      });
  };

  return (
    <div className="login-container">
      <h2 className="login-heading">Login</h2>
      <div id="recaptcha-container"></div>
      <div className="login-methods">
        <button
          className={`login-method-button ${
            loginMethod === "email" ? "active" : ""
          }`}
          onClick={() => handleLoginMethodChange("email")}
        >
          Email/Password
        </button>
        <button
          className={`login-method-button ${
            loginMethod === "phone" ? "active" : ""
          }`}
          onClick={() => handleLoginMethodChange("phone")}
        >
          Phone Number
        </button>
      </div>
      {loginMethod === "email" && (
        <div className="input-group">
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
        </div>
      )}
      {loginMethod === "phone" && (
        <div className="input-group">
          <input
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="login-input"
          />
          <button className="send-otp-button" onClick={onSignup}>
            Send OTP
          </button>
        </div>
      )}
      {showOtpInput && (
        <div className="input-group">
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className="login-input"
          />
        </div>
      )}
      <button className="login-button" onClick={handleLogin}>
        Login
      </button>
      <div className="do-not-have-account">
        <p> Do not have an accocunt </p>

        <a href="/signup"> create one</a>
      </div>
      {/* Progress Dialog */}
      <Dialog open={openDialog}>
        <div className="dialog-content">
          <img src={logogif} alt="Loading" className="logo-gif" />
          <p>{loading ? "Processing..." : "Processing..."}</p>
        </div>
      </Dialog>
    </div>
  );
};

export default Login;
