import React, { useState, useEffect } from "react";
import { realTimeDb } from "../register/firebase";
import { ref, get } from "firebase/database";
import "./Blogs.css";
import add from "../../images/add.png";
import arrow from "../../images/arrow.png";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";

function Blogs() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const readAllPosts = async () => {
      try {
        const postRef = ref(realTimeDb, "blog_posts");
        get(postRef).then((snapshot) => {
          if (snapshot.exists()) {
            const postArray = Object.entries(snapshot.val()).map(
              ([id, data]) => ({ id, ...data })
            );
            setPosts(postArray);
            console.log(postArray);
          } else {
            console.log("No posts found");
          }
        });
      } catch (error) {
        console.error("Error reading posts:", error);
      }
    };

    readAllPosts();
  }, []);

  return (
    <div>
      <Navbar />
      <div className="post-container">
        <h1 className="title">Blogs</h1>
        <ul>
          {posts.map((post) => (
            <div key={post.id} className="post-card">
              <li className="post-card-title">
                <h2>{post.title}</h2>
                {/* Check if post is not null before rendering Link */}
                {post && (
                  <Link
                    to={{ pathname: `/blog/${post.id}`, state: { post } }}
                    className="post-link"
                  >
                    <img
                      src={arrow}
                      className="post-card-title-img"
                      alt="expand"
                    />
                  </Link>
                )}
              </li>
              {/* <div className="comments">
                <p> Comments ({post.comments})</p>
                <img src={add} alt="expand" />
                </div> */}
            </div>
          ))}
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default Blogs;
