import axios from "axios";
import StrategyService from "../services/StrategyService";

class ChartsService {
  static async getOpenInterest(onSuccess, onFailure) {
    const URL = "https://replayserver.algoturtle.com/get_open_interest";

    try {
      const response = await axios.get(URL);
      const oiData = response.data;

      const combinedData = [];

      Object.entries(oiData).forEach(([key, value]) => {
        if (key.includes("FUT")) {
          console.log("Skipping FUT data", Object.entries(oiData).length);
          return;
        }

        const xValue = key.substring(14, 19);
        const openInterest = value.open_interest / 100000;
        const openInterestChange =
          Math.round((value.open_interest_change / 100000) * 100) / 100;

        // Check if the entry for xValue already exists
        const existingEntry = combinedData.find(
          (entry) => entry.key === xValue
        );

        if (existingEntry) {
          // Add to existing open interest for CE or PE
          if (key.includes("CE")) {
            existingEntry.open_interest_ce += openInterest;
            existingEntry.open_interest_change_ce += openInterestChange;
          } else if (key.includes("PE")) {
            existingEntry.open_interest_pe += openInterest;
            existingEntry.open_interest_change_pe += openInterestChange;
          }
        } else {
          // Create a new entry with initial values
          combinedData.push({
            key: xValue,
            open_interest_ce: key.includes("CE") ? openInterest : 0,
            open_interest_pe: key.includes("PE") ? openInterest : 0,

            open_interest_change_pe: key.includes("PE")
              ? openInterestChange
              : 0,

            open_interest_change_ce: key.includes("CE")
              ? openInterestChange
              : 0,
          });
        }
      });

      onSuccess(combinedData);
    } catch (error) {
      onFailure(error);
      console.error("Error fetching data:", error);
    }
  }

  static async getOIFiveMinData(onSuccess, onFailure) {
    try {
      const response = await axios.get(
        "https://replayserver.algoturtle.com//get_oi_data_5_minutes"
      );
      const oiDataFivemin = response.data;

      const combinedData = Object.entries(oiDataFivemin).map(([key, value]) => {
        return {
          timeStamp: key,
          time: ChartsService.formatDateTime(key),
          ...value,
        };
      });
      const lineData = Object.entries(oiDataFivemin).map(([key, value]) => {
        return {
          time: key,
          value: value.diff,
        };
      });

      onSuccess([combinedData, lineData]);
    } catch (err) {
      onFailure(err);
    }
  }

  static async getOIFiveMinDataForIntervalLineChart(
    interval,
    onSuccess,
    onFailure
  ) {
    try {
      const response = await axios.get(
        "https://replayserver.algoturtle.com/get_oi_data_5_minutes"
      );
      const oiDataFivemin = response.data;

      const combinedData = Object.entries(oiDataFivemin).map(([key, value]) => {
        return {
          timeStamp: key,
          time: ChartsService.formatDateTime(key),
          ...value,
        };
      });

      let lineData = Object.entries(oiDataFivemin).map(([key, value]) => {
        return {
          time: key,
          value: value.diff,
        };
      });

      lineData = ChartsService.getDataAfterIntervalApply(lineData, interval);
      onSuccess(lineData);
    } catch (err) {
      onFailure(err);
    }
  }

  static getDataAfterIntervalApply(lineData, interval) {
    const filteredData = [];
    let i = 0;

    lineData.forEach((dataPoint) => {
      if (((i + 1) * 5) % interval === 0) {
        filteredData.push(dataPoint);
      }
      i++;
    });

    return filteredData;
  }

  static async getOIFiveMinDataForIntervalTableChart(
    interval,
    onSuccess,
    onFailure
  ) {
    try {
      const response = await axios.get(
        "https://replayserver.algoturtle.com/get_oi_data_5_minutes"
      );
      const oiDataFivemin = response.data;

      let combinedData = Object.entries(oiDataFivemin).map(([key, value]) => {
        return {
          timeStamp: key,
          time: ChartsService.formatDateTime(key),
          ...value,
        };
      });

      combinedData = ChartsService.getDataAfterIntervalApplyTableChart(
        combinedData,
        interval
      );
      onSuccess(combinedData);
    } catch (err) {
      onFailure(err);
    }
  }

  static getDataAfterIntervalApplyTableChart(combinedData, interval) {
    const filteredData = [];
    let i = 0;

    combinedData.forEach((dataPoint) => {
      if (((i + 1) * 5) % interval === 0) {
        filteredData.push(dataPoint);
      }
      i++;
    });

    return filteredData;
  }
  static formatDateTime(timestamp) {
    const date = new Date(Number(timestamp));

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutesStr}`; // Returns time in 24-hour format
  }

  static formatDateTimeYear(timestamp) {
    const date = new Date(Number(timestamp));

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
    const day = date.getDate().toString().padStart(2, "0");

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`; // Returns formatted date and time
  }
}

export default ChartsService;
