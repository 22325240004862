import React from "react";
import "./HomePageFaqs.css";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
function HomePageFaqs() {
  const FAQs = [
    {
      question: "What makes Algoturtle unique?",
      answer:
        "	India's first Ai based algo trading platform. Develop your own strategies with us or use one of our own. Strategies available for every market condition, trending or sideways.Expiry Special (CAP) (Nifty) Amazing strategies. Unique UI",
    },
    {
      question: "What is Algoturtle?",
      answer:
        "Algoturtle is India's first AI-based algorithmic trading platform.",
    },
    ,
    {
      question: "Why choose Algoturtle?",
      answer:
        "	Trade like never before. Trade without emotions. Stress-free, systematic and disciplined.",
    },
    {
      question: "Can I customize/ develop strategies ?",
      answer:
        "		Well of course YOU CAN.We specialize in Custom strategies.Custom strategies are one of our higher selling product.",
    },
    {
      question: "How do I get started?",
      answer: "	Register here our team will contact you. REGISTER NOW",
    },

    // Add more FAQs as needed
  ];

  const getRandomLightColor = () => {
    const letters = "ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  return (
    <div className="faq--container">
      <h1 className="title-hme">Frequently Asked Questions</h1>
      <div className="card--container">
        {FAQs.map((faq, index) => (
          <div
            className="card-faq"
            style={{ backgroundColor: getRandomLightColor() }}
          >
            <h4>{faq.question}</h4>
            <p>{faq.answer}</p>
          </div>
        ))}
        <Button className="viewall--btn">
          <Link to="/faqs" className="viewall">
            View all
          </Link>
        </Button>
      </div>
    </div>
  );
}

export default HomePageFaqs;
