import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/pages/Home";
import FAQs from "./components/FAQs";
import Register from "./components/register/Register";
import PricingComparison from "./components/pages/PricingComparison";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutUs";
import SuccessPage from "./components/SuccessPage";
import FailurePage from "./components/FailurePage";
import PaymentTable from "./components/pages/PaymentTable";
import BlogDetails from "./components/BlogDetails";
import Blogs from "./components/pages/Blogs";
import QrCards from "./components/QrCards";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Support from "./components/Support";
import UpgradeToPro from "./components/UpgradeToPro";
import Login from "./components/Login";
import RunningStrategies from "./components/RunningStrategies";
import RunStrategyActivity from "./components/RunStrategyActivity";
import Signup from "./components/Signup";
import PhoneNumberVerificationPage from "./components/PhoneNumberVerificationPage";

function App() {
  const currentPath = window.location.pathname;

  const strategy =
    // Example strategy data
    {
      uid: "1", // Example UID
      strategyName: "Strategy 1",
      displayName: "My First Strategy",
      sop: "Some SOP",
      maxLossPercentage: 10,
      maxLots: 5,
      maxProfitPercentage: 20,
      minProfitPercentage: 5,
      trending: "Yes",
      date: "2024-06-22",
      initialCapital: 10000,
      type: "Type A",
    };
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/faqs" exact element={<FAQs />} />
          <Route path="/register" exact element={<Register />} />
          <Route path="/pricing" exact element={<PaymentTable />} />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/success" exact element={<SuccessPage />} />
          <Route path="/failure" exact element={<FailurePage />} />
          <Route path="/blogs" exact element={<Blogs />} />
          <Route path="/dashboard" exact element={<RunningStrategies />} />
          <Route path="/blog/:id" exact element={<BlogDetails />} />
          <Route path="/qrcard" exact element={<QrCards />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/customer-support" exact element={<Support />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/upgrade-to-pro" exact element={<UpgradeToPro />} />
          <Route
            path="/verify-phonenumber/:uid"
            element={<PhoneNumberVerificationPage />}
          />
          <Route path="/signup" exact element={<Signup />} />
          <Route
            path="/runstrategy/:strategyName"
            element={<RunStrategyActivity strategy={strategy} />} // Here, you can pass the strategy based on your data structure
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
