import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDoc, getDocs } from "firebase/firestore";
import primimum from "../images/premium.png";
import "./UpgradeToPro.css";
import { auth } from "./register/firebase";

import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";

import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
function UpgradeToPro() {
  const [uid, setUid] = useState(null);
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);

  const [openDialog, setOpenDialog] = useState(true); // State to control whether the dialog is open

  const [loading, setLoading] = useState(true);

  const loadPayment = async (priceId) => {
    setLoading(true);
    setOpenDialog(true);
    if (uid) {
      const body = {
        price_id: priceId,
        user_id: uid ? uid : "",
      };

      try {
        console.log("Starting to load payment...");
        const response = await axios.post(
          "https://payments.algoturtle.in/payments/create-checkout-session",
          body,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        console.log("Payment loaded:", response.data);
        window.location.href = response.data;
        return response.data;
      } catch (error) {
        console.error("Error loading payment:", error);
      } finally {
        setLoading(false);
        setOpenDialog(false);
        console.log("Payment loading process completed.");
      }
    } else {
      const searchParams = new URLSearchParams(window.location.search);
      const uidParam = searchParams.get("uid");
      if (!uidParam || !uid) {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            const uid = user.uid;
            setUid(uid);
            if (window.location.pathname === "/upgrade-to-pro") {
              navigate(`/upgrade-to-pro?uid=${user.uid}`);
            }
          } else {
            console.log("redirect required ");
            if (window.location.pathname === "/upgrade-to-pro") {
              navigate("/login");
            }
            console.log("redirect completed ");
          }
        });
        setLoading(false);
        setOpenDialog(false);
      } else {
        console.log("NOT redirect required ");
      }
    }
  };

  const extractUidFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const uidParam = searchParams.get("uid");
    setUid(uidParam);

    if (!(window.location.pathname === "/upgrade-to-pro")) {
      setLoading(false);
      setOpenDialog(false);
    }
  };
  useEffect(() => {
    extractUidFromUrl();

    const fetchAdditionalDetails = async () => {
      try {
        const priceInfoUrls = [
          // "https://payments.algoturtle.in/product/price_info?id=price_1Ooh9eSFyRA0FGivTgB9dJEE",
          // "https://payments.algoturtle.in/product/price_info?id=price_1OzdvgSFyRA0FGivwE96sBRE",
          "https://payments.algoturtle.in/product/price_info?id=price_1Ooh9KSFyRA0FGivFVqJTuxu",
        ];

        const promises = priceInfoUrls.map(async (url) => {
          const response = await fetch(url);
          const data = await response.json();
          const price = data.unit_amount_decimal / 100; // Adjust as needed
          const prev_price = data.plan_type === "basic" ? 7000 : 21000;
          const discount = ((prev_price - price) / prev_price) * 100;
          return {
            name: data.plan_type, // Adjust as needed
            price: price,
            priceId: data.id, // Adjust as needed
            benefits: Object.values(data.metadata),
            benefitsMetadata: data,
            prev_price: prev_price,
            discount: discount.toFixed(2),
          };
        });

        const fetchedPlans = await Promise.all(promises);
        setPlans(fetchedPlans);
        setLoading(false);
        setOpenDialog(false);
      } catch (error) {
        console.error("Error fetching additional details:", error);
      }
    };

    fetchAdditionalDetails();
  }, [extractUidFromUrl]);
  return (
    <div className="parent-container">
      <ul className="pricingTable-firstTable">
        {plans.map((plan) => (
          <li class="pricingTable-firstTable_table">
            <p className="discount-badge-trail">
              <span className="discount-badge-span"> 7 days free trail </span>
            </p>
            <p className="discount-badge">
              <span className="discount-badge-span">
                {plan.discount} % off{" "}
              </span>
            </p>
            <h1 class="pricingTable-firstTable_table__header">{plan.name}</h1>
            <p class="pricingTable-firstTable_table__pricing">
              <span>Rs. </span>
              <span>{plan.price}</span>
              <span
                style={{
                  fontSize: "12px",
                  color: "red",
                  margin: "5px",
                  textDecoration: "line-through",
                }}
              >
                {plan.prev_price}
              </span>

              <span>Month</span>
            </p>
            <ul class="pricingTable-firstTable_table__options">
              {plan.benefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>

            <div className="button-container">
              <img src={primimum} alt="Premium Image" className="button-icon" />
              <p
                className="button-text"
                onClick={() => loadPayment(plan.priceId)}
              >
                Upgrade to {plan.name}
              </p>
            </div>
          </li>
        ))}
      </ul>
      <Dialog open={openDialog}>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <p>{loading ? "Loading..." : "Logging in..."}</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UpgradeToPro;
