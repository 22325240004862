// src/services/StrategyService.js
import { db, auth } from "../components/register/firebase";
import { format } from "date-fns";

import axios from "axios";
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { da } from "date-fns/locale";

const DEFAULT_INITIAL_CAPITAL = 100000;
const DEFAULT_MAX_LOSS_PERCENTAGE = 20;
const DEFAULT_MAX_LOTS = 10;
const DEFAULT_MAX_PROFIT_PERCENTAGE = 50;
const DEFAULT_MIN_PROFIT_PERCENTAGE = 10;
const API_BASE_URL = "https://algoturtle.com";
const formatTime = (date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutesStr + " " + ampm;
  return strTime;
};

class StrategyService {
  static formatDateTime(date) {
    if (!(date instanceof Date)) {
      console.error("Invalid Date object:", date);
      return "";
    }

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutesStr + " " + ampm;
    return strTime;
  }
  static async getExecutedOrder(docId, onSuccess, onFailure) {
    try {
      const uid = auth.currentUser.uid; // Assuming auth is your Firebase authentication instance

      // Path to the orders collection
      const ordersCollectionRef = collection(
        db,
        `users/${uid}/executed_strategies/${docId}/orders`
      );

      // Create a query to order the documents by completed_at in descending order
      const ordersQuery = query(
        ordersCollectionRef,
        orderBy("completed_at", "desc")
      );

      // Get the documents from the collection
      const querySnapshot = await getDocs(ordersQuery);

      if (querySnapshot.empty) {
        onFailure("You do not have executed orders"); // Call onFailure callback if no documents found
      } else {
        const orderModels = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            brokerOrderId: data.broker_order_id,
            brokerOrderReport: data.broker_order_report || "",
            brokerType: data.broker_type,
            completedAt: data.completed_at.toDate(), // Convert Firestore Timestamp to JavaScript Date
            executedStrategyId: data.executed_strategy_id,
            orderType: data.order_type,
            placedAt: formatTime(data.placed_at.toDate()), // Convert and format Firestore Timestamp to JavaScript Date
            price: data.price,
            qty: data.qty,
            stock: data.stock,
            stockType: data.stock_type,
          };
        });

        onSuccess(orderModels); // Call onSuccess callback with the processed data
      }
    } catch (error) {
      console.error("Error fetching executed orders:", error.message);
      onFailure(error.message); // Call onFailure callback with error message
    }
  }

  static async userDetails(uid, onSuccess, onFailure) {
    try {
      const docRef = doc(db, "users", auth.currentUser.uid); // Create a document reference
      const docSnapShot = await getDoc(docRef); // Fetch the document
      if (docSnapShot.exists()) {
        // console.log("Document data:", docSnapShot.data());
        onSuccess(docSnapShot.data()); // Pass the document data to the onSuccess callback
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching brokers:", error);
      if (onFailure) onFailure(error);
    }
  }

  static async fetchListOfStrategiesFromDB(collectionName) {
    try {
      //console.log("Fetched list of strategies from db");
      const date = new Date();
      const formattedDate = format(date, "dd/MM/yyyy");
      const todayDate = new Date().toISOString().slice(0, 10);
      let collectionReference;

      collectionReference = collection(db, collectionName);

      const querySnapshot = await getDocs(collectionReference);
      const categoryModels = [];

      // Process each document in the query snapshot
      for (const doc of querySnapshot.docs) {
        try {
          if (doc.exists) {
            const data = doc.data();
            const initialCapital =
              data.initial_capital || DEFAULT_INITIAL_CAPITAL;
            const maxLossPercentage =
              data.max_loss_percent || DEFAULT_MAX_LOSS_PERCENTAGE;
            const maxLots = data.max_lots || DEFAULT_MAX_LOTS;
            const maxProfitPercentage =
              data.max_profit_percent || DEFAULT_MAX_PROFIT_PERCENTAGE;
            const minProfitPercentage =
              data.min_profit_percent || DEFAULT_MIN_PROFIT_PERCENTAGE;
            const sop = data.sop || "yet to add SOP";
            const strategyName = data.strategy_name;
            const displayName = data.display_name;
            const trending = data.Trending;

            // Create a category model object for each document
            const categoryModel = {
              strategy_collection: strategyName + collection,
              maxLossPercentage,
              maxLots,
              maxProfitPercentage,
              minProfitPercentage,
              sop,
              strategyName,
              displayName,
              trending,
              date: formattedDate,
              initialCapital,
              type: collection,
            };
            // console.log(categoryModels);
            categoryModels.push(categoryModel);
          } else {
            console.warn("Document does not exist");
          }
        } catch (error) {
          console.error("Error processing document:", error);
        }
      }

      // Simulate inserting into a local database (e.g., IndexedDB)
      // You can use a library like Dexie to manage IndexedDB

      // Store today's date in local storage
      localStorage.setItem(collection, todayDate);

      return categoryModels;
    } catch (error) {
      throw new Error(`Error fetching documents: ${error.message}`);
    }
  }
  static async fetchPrivateStrategiesFromDB() {
    try {
      //console.log("Fetched private strategies from db ");
      const date = new Date();
      const formattedDate = format(date, "dd/MM/yyyy");
      const todayDate = new Date().toISOString().slice(0, 10);
      const uid = auth.currentUser.uid;
      const categoryModels = [];
      if (uid) {
        // console.log("Fetched private strategies inside if ");
        const collectionReference = collection(
          db,
          "users",
          auth.currentUser.uid,
          "private_strategies"
        );
        const querySnapshot = await getDocs(collectionReference);
        //console.log(collectionReference);
        // Process each document in the query snapshot
        for (const doc of querySnapshot.docs) {
          try {
            if (doc.exists) {
              const data = doc.data();
              const initialCapital =
                data.initial_capital || DEFAULT_INITIAL_CAPITAL;
              const maxLossPercentage =
                data.max_loss_percent || DEFAULT_MAX_LOSS_PERCENTAGE;
              const maxLots = data.max_lots || DEFAULT_MAX_LOTS;
              const maxProfitPercentage =
                data.max_profit_percent || DEFAULT_MAX_PROFIT_PERCENTAGE;
              const minProfitPercentage =
                data.min_profit_percent || DEFAULT_MIN_PROFIT_PERCENTAGE;
              const sop = data.sop || "yet to add SOP";
              const strategyName = data.strategy_name;
              const displayName = data.display_name;
              const trending = data.Trending;

              // Create a category model object for each document
              const categoryModel = {
                strategy_collection: strategyName + collection,
                maxLossPercentage,
                maxLots,
                maxProfitPercentage,
                minProfitPercentage,
                sop,
                strategyName,
                displayName,
                trending,
                date: formattedDate,
                initialCapital,
                type: collection,
              };
              //  console.log(categoryModel);
              categoryModels.push(categoryModel);
            } else {
              console.warn("Document does not exist");
            }
          } catch (error) {
            console.error("Error processing document:", error);
          }
        }
      }

      // Simulate inserting into a local database (e.g., IndexedDB)
      // You can use a library like Dexie to manage IndexedDB

      // Store today's date in local storage
      localStorage.setItem(collection, todayDate);

      return categoryModels;
    } catch (error) {
      throw new Error(`Error fetching documents: ${error.message}`);
    }
  }
  static async stopStrategy(strategy, onSuccess, onFailure) {
    try {
      // Retrieve the token
      const token = await auth.currentUser.getIdToken(true);
      // Construct the request body
      const requestBody = {
        user_id: auth.currentUser.uid,
        strategy_name: strategy.strategy_id,
      };

      // Make the request with Axios
      const response = await axios.post(
        "https://algoturtle.com/strategy_manager/stop_strategy",
        requestBody,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // Handle successful response
      //console.log("Response:", response.data);
      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      // Handle errors
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error(
          "Server responded with status code:",
          error.response.status
        );
        console.error("Server response data:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up the request:", error.message);
      }
      console.error("Error stopping strategy:", error);
      if (onFailure) onFailure(error);
    }
  }
  static async runStrategy(jsonObject, onSuccess, onFailure) {
    console.log("requeest :", jsonObject);
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.post(
        "https://algoturtle.com/strategy_manager/run_strategy",
        jsonObject,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      if (error.response) {
        console.error(
          "Server responded with status code:",
          error.response.status
        );
        console.error("Server response data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
      console.error("Error running strategy:", error);
      if (onFailure) onFailure(error);
    }
  }
  static async getStocks(onSuccess, onFailure) {
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.get(
        "https://algoturtle.com//stock_vendor/get_stocks",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const stockValues = [];

      for (const stock of response.data.body) {
        //   console.log("Stock:", stock); // Logging each stock item object
        // console.log("Stock value:", stock.value); // Logging only the 'value' property
        stockValues.push(stock.value);
      }
      stockValues.sort((a, b) => {
        // Splitting each string by spaces to extract the third element
        const arrA = a.split(" ");
        const arrB = b.split(" ");

        // Parsing the third element (index 2) as integers for comparison
        const valueA = parseInt(arrA[2]);
        const valueB = parseInt(arrB[2]);

        // Comparing the parsed values
        return valueA - valueB;
      });
      if (onSuccess) onSuccess(stockValues);
    } catch (error) {
      console.error("Error fetching stocks:", error);
      if (onFailure) onFailure(error);
    }
  }
  static async fetchBrokers(onSuccess, onFailure) {
    try {
      const collectionReference = collection(
        db,
        "users",
        auth.currentUser.uid,
        "brokers"
      );
      const querySnapshot = await getDocs(collectionReference);

      const brokers = [];

      // Process each document in the query snapshot
      for (const doc of querySnapshot.docs) {
        brokers.push({ id: doc.id, broker_type: doc.data().broker_type });
      }

      //console.log("Brokers:", brokers);
      onSuccess(brokers);
    } catch (error) {
      console.error("Error fetching brokers:", error);
      if (onFailure) onFailure(error);
    }
  }
  static async fetchExecutedOrders(tomorrow, today, onSuccess, onFailure) {
    try {
      // Get today's date

      // Now you can use these variables in your useEffect or other functions as needed

      console.log("entered");
      const uid = auth.currentUser.uid;
      const collectionReference = collection(
        db,
        "users",
        uid,
        "executed_strategies"
      );

      const q = query(
        collectionReference,
        where("strategy_metadata.created_at", "<", tomorrow),
        where("strategy_metadata.created_at", ">=", today)
      );

      const querySnapshot = await getDocs(q);
      const orders = [];

      console.log(querySnapshot.getDoc);

      querySnapshot.forEach((doc) => {
        orders.push(doc.data()); // Adjust as per your document structure
      });
      console.log(orders);

      onSuccess(orders);
    } catch (error) {
      console.error("Error fetching orders:", error);
      if (onFailure) onFailure(error);
    }
  }
  // static async fetchBrokerDetails(id, onSuccess, onFailure) {
  //   try {
  //     console.log("entered");
  //     const uid = auth.currentUser.uid;
  //     const docRef = collection(db, "users", uid, "brokers", id);
  //     const docSnapShot = await getDoc(docRef); // Fetch the document

  //     if (docSnapShot.exists()) {
  //       console.log("Document data:", docSnapShot.data());
  //       onSuccess(docSnapShot.data()); // Pass the document data to the onSuccess callback
  //     } else {
  //       console.log("No such document!");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching brokers:", error);
  //     if (onFailure) onFailure(error);
  //   }
  // }
  static async fetchBrokerDetails(brokerId, onSuccess, onFailure) {
    try {
      // Correct the path to reflect document reference
      const docRef = doc(db, "users", auth.currentUser.uid, "brokers", "NEO"); // Create a document reference
      const docSnapShot = await getDoc(docRef); // Fetch the document

      if (docSnapShot.exists()) {
        //console.log("Document data:", docSnapShot.data());
        onSuccess(docSnapShot.data()); // Pass the document data to the onSuccess callback
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      onFailure(error);
    }
  }

  static async saveBroker(jsonObject, onSuccess, onFailure) {
    console.log(" saving broker");
    console.log(jsonObject);
    try {
      const token = await auth.currentUser.getIdToken(true);
      const response = await axios.post(
        "https://algoturtle.com//broker/save_broker",
        jsonObject,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      if (error.response) {
        console.error(
          "Server responded with status code:",
          error.response.status
        );
        console.error("Server response data:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
      console.error("Error running strategy:", error);
      if (onFailure) onFailure(error);
    }
  }

  static async fetchAllRunning(onSuccess, onFailure) {
    console.log("fetching data");
    try {
      const userRef = doc(db, "users", auth.currentUser.uid);
      const executedStrategiesRef = collection(userRef, "executed_strategies");
      const q = query(
        executedStrategiesRef,
        where("strategy_status", "==", "RUNNING")
      );

      const querySnapshot = await getDocs(q);

      const list = [];

      if (querySnapshot.empty) {
        onSuccess([]);
      } else {
        querySnapshot.forEach((documentSnapshot) => {
          const data = documentSnapshot.data();

          // const max_loss = strategy_metadata.additional_metadata.max_loss;
          // const max_loss_model = {
          //   description: max_loss.description,
          //   display_name: max_loss.display_name,
          //   key: max_loss.key,
          //   type: max_loss.type,
          //   value: max_loss.value,
          // };

          // const max_lots = strategy_metadata.additional_metadata.max_lots;
          // const max_lots_model = {
          //   description: max_lots.description,
          //   display_name: max_lots.display_name,
          //   key: max_lots.key,
          //   type: max_lots.type,
          //   value: max_lots.value,
          // };

          // const profit_range_max =
          //   strategy_metadata.additional_metadata.profit_range_max;
          // const profit_max = {
          //   description: profit_range_max.description,
          //   display_name: profit_range_max.display_name,
          //   key: profit_range_max.key,
          //   type: profit_range_max.type,
          //   value: profit_range_max.value,
          // };

          // const profit_range_min =
          //   strategy_metadata.additional_metadata.profit_range_min;
          // const profit_min = {
          //   description: profit_range_min.description,
          //   display_name: profit_range_min.display_name,
          //   key: profit_range_min.key,
          //   type: profit_range_min.type,
          //   value: profit_range_min.value,
          // };

          // const additionalMetadataModel = {
          //   max_loss_model,
          //   max_lots_model,
          //   profit_max,
          //   profit_min,
          // };

          // const strategyMetadata = {
          //   additionalMetadataModel,
          //   createdAt: strategy_metadata.created_at.toDate(),
          //   description: strategy_metadata.description,
          //   display_name: strategy_metadata.display_name,
          //   entry: strategy_metadata.entry,
          //   exit: strategy_metadata.exit,
          //   initial_capital: strategy_metadata.initial_capital,
          //   interval: strategy_metadata.interval,
          //   is_custom: strategy_metadata.is_custom,
          //   is_stock_required: strategy_metadata.is_stock_required,
          //   is_subscribed: strategy_metadata.is_subscribed,
          //   owner: strategy_metadata.owner || "",
          //   pl: strategy_metadata.pl,
          //   quantity: strategy_metadata.quantity,
          //   start_time: strategy_metadata.start_time,
          //   stop_time: strategy_metadata.stop_time,
          //   strategy_name: strategy_metadata.strategy_name,
          // };

          // const executedStrategyModel = {
          //   broker_type: data.broker_type,
          //   display_name: data.display_name,
          //   end_time: data.end_time || "",
          //   failure_reason: data.failure_reason || "",
          //   id: data.id,
          //   pnl: data.pnl || "",
          //   start_time: data.start_time,
          //   stock: data.stock,
          //   stock_type: data.stock_type,
          //   strategyMetadata,
          //   strategy_name: data.strategy_name,
          //   strategy_status: data.strategy_status,
          //   docId: documentSnapshot.id,
          // };

          list.push(data);
          console.log("data loading");
          console.log(data);
        });

        onSuccess(list);
      }
    } catch (error) {
      onFailure(error.message);
    }
  }
}

export default StrategyService;
