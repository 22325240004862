import React, { useEffect, useRef, useState } from "react";
import IntroSection from "../IntroSection";
import "./Home.css";

import { Link } from "react-router-dom";

import developIg from "../../images/develop.png";
import trendImg from "../../images/trend.png";
import expireImg from "../../images/expired.png";
import playStore from "../../images/playstore.png";
import qrCode from "../../images/algoTurtle-qrcode.png";
import Register from "../register/Register";
import mobileImg from "../../images/mobile.png";
import HomePageFaqs from "./HomePageFaqs";

import star from "../../images/star.png";
import more from "../../images/more.png";

import createAccount from "../../images/singup.jpeg";
import saveBroker from "../../images/save-broker.jpeg";
import moniterImg from "../../images/moniter.jpeg";
import payment from "../../images/pay.jpeg";

import codelogic from "../../images/codelogic.png";
import customerSupport from "../../images/customer-service.png";
import FeedbackContainer from "../FeedbackContainer";
import Footer from "../Footer";
import Navbar from "../Navbar";

function Home() {
  const [showButtons, setShowButtons] = useState(false);

  const toggleButtons = () => {
    setShowButtons(!showButtons);
  };

  const feedbackSubConRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (feedbackSubConRef.current) {
        feedbackSubConRef.current.scrollBy({
          left: 1, // Adjust this value for scroll speed
          behavior: "smooth",
        });
      }
    }, 20); // Adjust this value for scroll speed

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="main-home-con">
        <div className="float-con">
          <div className="floating-btn" onClick={toggleButtons}>
            <img className="floating-btn-img" src={more} />
          </div>
          {showButtons && (
            <div>
              <div className="float-1">
                <a href="/about-us">
                  <img
                    className="floating-btn-img-nofillter"
                    src={customerSupport}
                  />
                </a>
              </div>
              <div className="float-2">
                <a href="/register">
                  <img className="floating-btn-img-nofillter" src={codelogic} />
                </a>
              </div>
            </div>
          )}
        </div>
        {/* intro */}
        <div className="intro-con">
          <div className="intro-sub-container">
            <h1 className="intro-title">
              <span style={{ color: "#2D3192" }}>algo</span>
              <span style={{ color: "#008000" }}>Turtle</span> - India's first
              AI based algo trading platform
            </h1>
            <p className="intro-sub-title">What are you waiting for?</p>
            <div className="intro-btn-con">
              <a href="#getstart">
                <button className="intro-btn-get-started"> Get started</button>
              </a>
              <a href="https://www.youtube.com/@algoTurtle">
                <button className="intro-btn-watch-trailer">
                  {" "}
                  Watch Trailer
                </button>
              </a>
            </div>
          </div>
        </div>
        {/*  features */}
        <div className="feature-container-container">
          <h1 className="title-home">
            What are unique Feature in {/* <a href="/"> */}
            <span style={{ color: "#2D3192" }}>algo</span>
            <span style={{ color: "#008000" }}>Turtle</span>
            {/* </a> */}
          </h1>
          <div className="features-container">
            <div className="feature-card">
              <div>
                <img className="feature-icon" src={developIg} />
              </div>
              <div className="feature-content">
                <h3 className="feature-title">Develop your strategies</h3>
                <h4 className="feature-description">
                  AlgoTurtle develops proprietary algorithms using advanced
                  quantitative analysis and machine learning for profitable
                  trading. With robust risk management and strategic
                  partnerships, it ensures access to cutting-edge technologies.
                  Algo Turtle fosters long-term relationships through
                  personalized advisory services, transparent communication, and
                  continuous innovation in algorithmic trading.
                </h4>
              </div>
            </div>

            <div className="feature-card">
              <div>
                <img className="feature-icon" src={trendImg} />
              </div>
              <div>
                <h3 className="feature-title">
                  Strategies for Trending and Sideways Markets
                </h3>
                <h4 className="feature-description">
                  Traders adapt to trending markets with trend-following
                  strategies, using tools like moving averages and ADX to ride
                  directional momentum. In sideways markets, range-bound
                  strategies capitalize on price oscillations between support
                  and resistance, with breakout strategies utilized for
                  potential range breakouts
                </h4>
              </div>
            </div>

            <div className="feature-card">
              <div>
                <img className="feature-icon" src={developIg} />
              </div>
              <div>
                <h3 className="feature-title">EXPIRY SPECIAL NIFTY</h3>
                <h4 className="feature-description">
                  ZERO-HERO strategy for nifty options expairy, capture spikes
                  effortlessly.
                </h4>
              </div>
            </div>
          </div>
        </div>
        {/* how to start trading ith algoTurtle */}
        <div className="getstarted-container">
          {/* step 1 */}
          <h1 id="getstart" className="title-hme">
            How to get started with{" "}
            <span style={{ color: "#2D3192" }}>algo</span>
            <span style={{ color: "#008000" }}>Turtle</span>
          </h1>
          <div className="getstarted-item">
            <diV className="qr-card">
              <img className="qr-code" src={qrCode} />
              <p>OR</p>
              <a href="https://play.google.com/store/apps/details?id=com.proalgoturtle">
                <div className="download-app">
                  <img className="playstore" src={playStore} />
                  <p>Download</p>
                </div>
              </a>
            </diV>

            <div>
              <h2 style={{ color: "black" }}>
                {" "}
                Install <span style={{ color: "#2D3192" }}>algo</span>
                <span style={{ color: "#008000" }}>Turtle</span>
              </h2>
            </div>
          </div>

          {/* screen shots */}
          <div className="screenshots">
            <div className="screenshots-item">
              <diV className="mobile-card">
                <img className="mobile-img" src={createAccount} />
              </diV>
              <div>
                <h1> Create account</h1>
                <p>
                  After Installing app create accont in our app{" "}
                  <span style={{ color: "#2D3192" }}>algo</span>
                  <span style={{ color: "#008000" }}>Turtle</span>{" "}
                </p>
              </div>
            </div>

            <div className="screenshots-item">
              <div>
                <h1> Complete subscription</h1>
                <p>
                  Adter creating account in{" "}
                  <span style={{ color: "#2D3192" }}>algo</span>
                  <span style={{ color: "#008000" }}>Turtle</span> complete
                  subscription to get our services{" "}
                </p>
              </div>
              <diV className="mobile-card">
                <img className="mobile-img" src={payment} />
              </diV>
            </div>

            <div className="screenshots-item">
              <diV className="mobile-card">
                <img className="mobile-img" src={saveBroker} />
              </diV>
              <div>
                <h1> Save your broker</h1>
                <p>
                  After completing subscriptiop you can able to save broker to
                  run our strategies{" "}
                </p>
              </div>
            </div>

            <div className="screenshots-item">
              <div>
                <h1> Moniter RunningStrategies</h1>
                <p>
                  Run strategies in our app and moniter you profit and loss here
                  in home screen{" "}
                </p>
              </div>
              <diV className="mobile-card">
                <img className="mobile-img" src={moniterImg} />
              </diV>
            </div>
          </div>
        </div>
        {/* feedback */}
        <div>
          <Register />
          {/* <div className="feedback-container">
            <h1 className="title-hme">Customer Feedback</h1>
            <div className="feedback-sub-con">
              <div className="feedback-card">
                <div className="star-customer-container">
                  <div>
                    <img src={star} className="start-icon" />
                    <img src={star} className="start-icon" />
                    <img src={star} className="start-icon" />
                    <img src={star} className="start-icon" />
                    <img src={star} className="start-icon" />
                  </div>

                  <h4 className="customer-name">- Shalini Singh</h4>
                </div>
                <div className="feedback-description">
                  <h3> Exceptional App with Effective Strategies</h3>
                  <p>
                    {" "}
                    AlgoTurtle delivers an exceptional app experience with its
                    effective strategies, ensuring consistent and profitable
                    outcomes in the long run.
                  </p>
                </div>
              </div>

              <div className="feedback-card">
                <div className="star-customer-container">
                  <div>
                    <img src={star} className="start-icon" />
                    <img src={star} className="start-icon" />
                    <img src={star} className="start-icon" />
                    <img src={star} className="start-icon" />
                    <img src={star} className="start-icon" />
                  </div>

                  <h4 className="customer-name">- Pragti Kabra</h4>
                </div>
                <div className="feedback-description">
                  <h3> Stellar Service and Profitable Solutions</h3>
                  <p>
                    {" "}
                    Experience stellar service and discover profitable solutions
                    with AlgoTurtle's innovative approach and reliable
                    strategies.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <FeedbackContainer />
          <HomePageFaqs />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
