import React from "react";
import { Card } from "react-bootstrap";
import "./AboutUs.css";
import Footer from "./Footer";
import Navbar from "./Navbar";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <div className="about-us-container">
        <h2 className="about-us-heading">About Us</h2>
        <div className="about-us-content">
          <p>
            Our dedicated team of algorithmic trading professionals is committed
            to delivering an unparalleled algo trading platform that combines
            reliability with exceptional customer support and service. We
            understand the importance of providing a robust and user-friendly
            environment for traders, and we strive to meet and exceed their
            expectations.
          </p>

          <p>
            One of our key strengths lies in our specialization in custom
            strategies. We believe that each trader has a unique trading style,
            and our platform empowers you to develop personalized strategies
            that align with your specific preferences and goals. Whether you are
            a seasoned trader or a beginner, our platform is designed to cater
            to your individual needs, allowing you to implement strategies that
            resonate with your trading approach.
          </p>

          <p>
            At the heart of our offerings are AI-optimized strategies, provided
            to all our users at no cost. We recognize the significance of
            artificial intelligence in enhancing trading performance, and we are
            dedicated to democratizing access to cutting-edge technology. These
            AI-optimized strategies leverage the power of advanced algorithms to
            analyze market data, identify trends, and make data-driven
            decisions.
          </p>

          <p>
            Our commitment to innovation extends beyond just providing a
            platform – we aim to create a community of traders who can share
            insights, collaborate on strategies, and collectively thrive in the
            dynamic world of algorithmic trading. We foster an environment that
            encourages continuous learning and improvement, ensuring that our
            users stay at the forefront of industry trends.
          </p>

          <p>
            In addition to our comprehensive platform, we prioritize
            transparency and security. We understand the importance of trust in
            the financial industry, and we take all necessary measures to
            safeguard your data and transactions. Our platform is built with the
            latest security protocols to provide you with peace of mind as you
            engage in algorithmic trading.
          </p>

          <p>
            Customer satisfaction is at the core of our values, and our
            exceptional customer support team is always ready to assist you.
            Whether you have questions about the platform, need guidance on
            strategy development, or encounter any issues, our support team is
            dedicated to providing prompt and effective solutions. Your success
            is our success, and we are here to support you every step of the
            way.
          </p>

          <p>
            In summary, we are not just a platform; we are a community-driven
            ecosystem that empowers traders to thrive in the world of
            algorithmic trading. With a focus on customization, AI optimization,
            and unwavering customer support, we are shaping the future of
            algorithmic trading for traders worldwide. Join us on this exciting
            journey and experience the difference of a platform that puts your
            success first.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
